import { pairSources } from "@roe-monorepo/shared-features/src/pair/constants/pairSources";
import { links } from "@roe-monorepo/shared-features/src/shared/constants/links";
import { getFormattedAPY } from "@roe-monorepo/shared-features/src/shared/helpers/formatters";

import { DevelopmentExplanation } from "../../shared/components/DevelopmentExplanation";
import {
  Container,
  Content,
  Item,
  TooltipTitle,
  Title,
  SubTitle,
  Value,
  Link,
} from "../styles/SupplyApyTooltip";

import type { Pair } from "../../shared/queries/types/Pair";
import type { FC } from "react";

type SupplyAPYTooltipProps = Pick<Pair, "apy" | "source" | "swapFeesAPY">;

export const SupplyAPYTooltip: FC<SupplyAPYTooltipProps> = ({
  source,
  swapFeesAPY,
  apy,
}) => {
  const pairSourceTitle = pairSources[source];

  const formattedSwapFeesAPY = getFormattedAPY(swapFeesAPY);
  const borrowInterest = getFormattedAPY(apy);

  return (
    <Container>
      <TooltipTitle>Funding Rate Breakdown</TooltipTitle>
      <Content>
        <Item>
          <Value>{formattedSwapFeesAPY}</Value>
          <Title>{`${pairSourceTitle} Fees`}</Title>
          <SubTitle>(avg. over past 7 days)</SubTitle>
        </Item>
        <Item>
          <DevelopmentExplanation
            explanation={
              <Title>
                borrowInterest = lp.getReserveData( lp.getReservesList()[2] )[4]
                / 1e27
              </Title>
            }
          >
            <Value>{borrowInterest}</Value>
          </DevelopmentExplanation>
          <Title>ROE Borrow Interest</Title>
        </Item>
      </Content>
      <Link href={links.gitBookSupply}>Learn More</Link>
    </Container>
  );
};
