import { getImageSourceBySymbol } from "@roe-monorepo/shared-features/src/icons/helpers/getImageSourceBySymbol";

import {
  Container,
  FirstTokenIcon,
  SecondTokenIcon,
  TitleContainer,
  TokensContainer,
} from "../styles/TitleCell";

import type { Token } from "../../shared/queries/types/Token";
import type { FC } from "react";

export interface TitleCellProps {
  title: string;
  tokens: [Token["symbol"], Token["symbol"]];
}

export const TitleCell: FC<TitleCellProps> = ({ title, tokens }) => {
  const [imageSource0, imageSource1] = tokens.map(getImageSourceBySymbol);
  return (
    <Container>
      <TokensContainer>
        {imageSource0 ? <FirstTokenIcon src={imageSource0} /> : null}
        {imageSource1 ? <SecondTokenIcon src={imageSource1} /> : null}
      </TokensContainer>
      <TitleContainer>{title}</TitleContainer>
    </Container>
  );
};
