import tw from "twin.macro";

export const Container = tw.div`
  flex justify-center items-start flex-col
  w-full
  gap-2
`;

export const InfoContainer = tw.div`
  flex justify-center items-start flex-col
  bg-gray-newSemiLight
  w-full
  gap-2
  rounded-lg
`;

export const Title = tw.h2`
  text-white
  text-lg
  font-semibold
  px-2
`;

export const Row = tw.div`
  flex justify-between flex-row
  w-full
  px-2
`;

export const InfoValue = tw.span`
  text-white
  text-base
  font-semibold
`;

export const InfoValueRed = tw.span`
  text-red-dark
  text-base
  font-semibold
`;

export const BrandValue = tw.span`
  text-brand
  text-base
  font-semibold
`;
