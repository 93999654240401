import { getExp } from "@roe-monorepo/shared-features/src/shared/helpers/bigjs";
import Big from "big.js";

import type { Token } from "@roe-monorepo/shared-features/src/queries/types/Token";

export const getTokenAmount = (inputValueBig: Big, tokenData: Token) => {
  const multiplier = getExp(tokenData.decimals);
  const tokenAmount = inputValueBig.mul(multiplier).round(0, Big.roundDown);

  return tokenAmount.toString();
};
