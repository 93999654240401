import { ChainId } from "../types/ChainId";

import type { ChainMetadata } from "../types/ChainMetadata";

export const chainsMetadata: ChainMetadata[] = [
  {
    chainId: ChainId.MAINNET,

    addresses: {
      lendingPoolAddressesProviderRegistryAddress:
        "0x0029B254d039d8C5C88512a44EAa6FF999296009",

      roePm: "0x7515cd37707c700a0bd17D3E925b1b94f2C9B83f",
      qsRouterAddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",

      routerAddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      zapBoxAddress: "0x80e6Ef71A257c8495fdb862a531822A991830877",
    },
  },
  {
    chainId: ChainId.POLYGON,

    addresses: {
      lendingPoolAddressesProviderRegistryAddress:
        "0x1ceb99Acd9788bb7d7Ce4a90219cBb0627b008F9",

      roePm: "0xe8a6265bDd7080B4C5786b5633bC5bCc3F7160AE",
      qsRouterAddress: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",

      routerAddress: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
      zapBoxAddress: "0x75424486c5a6640e31EdBcd17d30bE4662446278",
    },
  },
  {
    chainId: ChainId.ARBITRUM,

    addresses: {
      lendingPoolAddressesProviderRegistryAddress:
        "0x01b76559D512Fa28aCc03630E8954405BcBB1E02",

      roePm: "0x1056b5664341487679d5B68854249318693dc53D",
      qsRouterAddress: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",

      routerAddress: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
      zapBoxAddress: "0xB90240106d7Fcff6566ab83299D90d81753eA19c",
    },
  },
];
