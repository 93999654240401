import { AnimatePresence } from "framer-motion";
import { useBoundingclientrect, useOutsideClick } from "rooks";

import { Container, Content } from "../styles/DropdownList";

import { DropdownOption } from "./DropdownOption";

import type { DropdownOptionConfig } from "../types/DropdownOptionConfig";
import type { DropdownProps } from "../types/DropdownProps";
import type { FC, MutableRefObject } from "react";

interface DropdownListProps {
  isShow: boolean;
  options: DropdownOptionConfig[];
  align: Required<DropdownProps["align"]>;
  parentReference: MutableRefObject<HTMLDivElement | null>;
  onHide: () => void;
}

export const DropdownList: FC<DropdownListProps> = ({
  isShow,
  options,
  align,
  parentReference,
  onHide,
}) => {
  const clientRect = useBoundingclientrect(parentReference);
  useOutsideClick(parentReference, onHide);

  const { width = 0, height = 0 } = clientRect ?? {};

  const topPosition = height + 10;
  const rightPosition = align === "right" ? "0" : "initial";

  return (
    <AnimatePresence>
      {isShow ? (
        <Container
          minWidth={width}
          rightPosition={rightPosition}
          topPosition={topPosition}
        >
          <Content>
            {options.map(({ title, icon, link, onClick }) => (
              <DropdownOption
                icon={icon}
                key={title}
                link={link}
                onClick={onClick}
                title={title}
              />
            ))}
          </Content>
        </Container>
      ) : null}
    </AnimatePresence>
  );
};
