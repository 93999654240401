import styled from "styled-components";
import tw from "twin.macro";

interface ButtonProps {
  disabled?: boolean;
  isError?: boolean;
}

export const Button = styled.button<ButtonProps>`
  ${tw`
    px-3 py-2
    border-2 rounded-lg
    border-gray-semiLight
    bg-gray-dark
    font-semibold text-sm text-white
    transition
  `}

  ${({ disabled = false }) =>
    disabled
      ? tw`opacity-60 cursor-not-allowed`
      : tw`hover:(text-brand border-brand) active:scale-90`}
  ${({ isError = false }) => isError && tw`text-red-dark border-red-dark`}
`;
