import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

export const Container = styled(motion.div).attrs({
  initial: { height: 0 },
  animate: { height: "auto" },
})`
  ${tw`
    flex justify-center w-full
  `}
`;

export const Link = styled.a.attrs({
  target: "_blank",
})`
  ${tw`
     flex
     justify-center
     items-center
     gap-2
     text-base 
     font-semibold
     text-blue-light
  `}
`;
