import { getFormattedCurrency } from "@roe-monorepo/shared-features/src/shared/helpers/formatters";
import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

import { useClosePositionModal } from "../../main-page/hooks/useClosePositionModal";
import { InfoRow } from "../../openPosition/components/InfoRow";
import { useOpenPositionInfoQuery } from "../../openPosition/hooks/useOpenPositionInfoQuery";
import { DevelopmentExplanation } from "../../shared/components/DevelopmentExplanation";
import { FundingRateExplanation } from "../../shared/components/FundingRateExplanation";
import { PnlExplanation } from "../../shared/components/PnlExplanation";
import { getFormattedAPY } from "../../shared/helpers/formatters";
import {
  Container,
  Row,
  InfoValue,
  InfoContainer,
  InfoValueRed,
} from "../styles/closeTradeInfo";

export const CloseTradeInfo = () => {
  const { chainId = ChainId.MAINNET, account = "" } = useWallet();

  const { modalState } = useClosePositionModal();

  const {
    pnl = "0",
    blockNumber,
    lendingPoolAddress = "",
    tokens,
    collateralTokenIndex,
  } = modalState ?? {};

  const symbol = collateralTokenIndex
    ? tokens?.[0] ?? "..."
    : tokens?.[1] ?? "...";

  const assetOracle = modalState?.addresses?.assetOracle ?? "";

  const { data, isLoading } = useOpenPositionInfoQuery(
    account,
    chainId,
    String(assetOracle),
    String(lendingPoolAddress),
    modalState,
    blockNumber
  );

  const { price, initialPrice, fundingRate = 0, priceChange = 0 } = data ?? {};

  return (
    <Container>
      <Row>
        <InfoValue>PNL</InfoValue>
        <DevelopmentExplanation explanation={<PnlExplanation />}>
          <InfoValueRed>
            {getFormattedCurrency(Number(pnl), { maximumFractionDigits: 2 })}
          </InfoValueRed>
        </DevelopmentExplanation>
      </Row>
      <InfoContainer>
        <InfoRow
          isLoading={isLoading}
          title={`${symbol} % Change`}
          value={price ? getFormattedAPY(priceChange) : "-"}
        />
        <InfoRow
          isLoading={isLoading}
          title={`Spot Price of ${symbol}`}
          value={price ? getFormattedCurrency(price) : "-"}
        />
        <InfoRow
          isLoading={isLoading}
          title={`Entry Price of ${symbol}`}
          value={initialPrice ? getFormattedCurrency(initialPrice) : "-"}
        />
        <InfoRow
          isLoading={isLoading}
          title="Funding Rate"
          value={
            <DevelopmentExplanation explanation={<FundingRateExplanation />}>
              <>{String(getFormattedAPY(fundingRate))}</>
            </DevelopmentExplanation>
          }
        />
      </InfoContainer>
    </Container>
  );
};
