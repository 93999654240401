import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

import { useClosePositionModal } from "../../main-page/hooks/useClosePositionModal";
import { InfoRow } from "../../openPosition/components/InfoRow";
import { useOpenPositionInfoQuery } from "../../openPosition/hooks/useOpenPositionInfoQuery";
import { DevelopmentExplanation } from "../../shared/components/DevelopmentExplanation";
import { Runway } from "../../shared/components/Runway";
import { RunwayExplanation } from "../../shared/components/RunwayExplanation";
import { getFormattedCurrency } from "../../shared/helpers/formatters";
import {
  Container,
  Row,
  InfoValue,
  InfoContainer,
  InfoValueRed,
} from "../styles/closeTradeInfo";

export const CloseTradePositionInfo = () => {
  const { modalState } = useClosePositionModal();
  const { chainId = ChainId.MAINNET, account = "" } = useWallet();

  const lendingPoolAddress = modalState?.lendingPoolAddress ?? "";

  const assetOracle = modalState?.addresses?.assetOracle ?? "";

  const { data, isLoading } = useOpenPositionInfoQuery(
    account,
    chainId,
    String(assetOracle),
    String(lendingPoolAddress),
    modalState
  );
  const { runway = 0 } = data ?? {};

  const {
    healthFactor = "N/A",
    leverage = "0",
    supplyValue = "0",
    initialDeposit = "0",
  } = modalState ?? {};

  return (
    <Container>
      <Row>
        <InfoValue>Health Factor</InfoValue>
        <InfoValueRed>{healthFactor}</InfoValueRed>
      </Row>
      <InfoContainer>
        <InfoRow
          isLoading={isLoading}
          title="Estimated Runway"
          value={
            <DevelopmentExplanation explanation={<RunwayExplanation />}>
              <Runway runway={runway} />
            </DevelopmentExplanation>
          }
        />
        <Row>
          <InfoValue>Collateral Supplied</InfoValue>
          <InfoValue>
            {getFormattedCurrency(Number(initialDeposit), {
              maximumFractionDigits: 2,
            })}
          </InfoValue>
        </Row>
        <InfoRow isLoading={false} title="Leverage" value={`${leverage}x`} />
        <InfoRow
          isLoading={false}
          title="Position"
          value={getFormattedCurrency(Number(supplyValue), {
            maximumFractionDigits: 2,
          })}
        />
      </InfoContainer>
    </Container>
  );
};
