import tw from "twin.macro";

export const Container = tw.div`
  flex flex-row justify-start items-center w-full
`;

export const Title = tw.div`
  text-white
  text-base
  font-semibold
  py-5
`;
