import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";

import { useShowOpenPositionModal } from "../../main-page/hooks/useOpenPositionModal";
import { Container, Description, Step } from "../styles/FundingRateExplanation";

export const PnlExplanation = () => {
  const { modalState } = useShowOpenPositionModal();

  const { accountData, pnl, leverage = 0 } = modalState ?? {};

  const { account = "" } = useWallet();

  const {
    initialDeposit = 0,
    initialDebt = 0,
    currentDebt = 0,
  } = accountData ?? {};

  return (
    <Container>
      {/* eslint-disable-next-line eslint-comments/disable-enable-pair */}
      {/* eslint-disable @typescript-eslint/restrict-template-expressions */}
      <Description>
        # Account data
        <br />
        accountData = lendingPool.getUserAccountData({account});
        <br />
        collateralDebtDifference = (accountData.totalCollateralETH -
        accountData.totalDebtETH) / 10e8;
        <br />
        collateralDebtDifference ={" "}
        {`(${accountData?.totalCollateralETH} -
          ${accountData?.totalDebtETH})`}{" "}
        / 10e8;
      </Description>
      <Step>{`collateralDebtDifference = ${currentDebt}`}</Step>

      <Description>
        # Open position event data
        <br />
        openPositionEventData = roePMContract.filters.OpenPosition(account,
        asset, null, null);
        <br />
        initialDebt = openPositionEventData.value / 1e8
        <br />
        {`initialDebt = ${initialDebt}`}
      </Description>

      <Description>
        # Initial deposit.
        <br />
        initialDeposit = initialDebt / leverage
        <br />
        initialDeposit = {`${initialDebt} / ${leverage}`}
      </Description>
      <Step>{`initialDeposit = ${initialDeposit}`}</Step>

      <Description>
        # Current PNL
        <br />
        pnl = collateralDebtDifference - initialDeposit
        <br />
        {`pnl = ${currentDebt} - ${initialDeposit}`}
      </Description>
      <Step>{`pnl = ${pnl ?? 0}`}</Step>
    </Container>
  );
};
