import { Dropdown } from "../../dropdown/components/Dropdown";
import { DropdownArrow } from "../../dropdown/components/DropdownArrow";
import { useDropdown } from "../../dropdown/hooks/useDropdown";
import { Content } from "../../dropdown/styles/DropdownOption";
import { DropdownButton } from "../styles/TokensDropdown";

import type { InputCardProps } from "./InputCard";
import type { FC } from "react";

type TokensDropdownProps = Pick<
  InputCardProps,
  "setInputValue" | "setTokenDataAddress" | "tokenData" | "tokens"
>;

export const TokensDropdown: FC<TokensDropdownProps> = ({
  tokenData,
  tokens = [],
  setTokenDataAddress = () => undefined,
  setInputValue,
}) => {
  const { isShowDropdown, toggleDropdown, hideDropdown } = useDropdown();

  const filteredTokens = tokens.filter(
    (token) => token?.address !== tokenData?.address
  );
  const options = filteredTokens.map((token) => ({
    title: token?.symbol ?? "-",

    onClick: () => {
      if (token) {
        setInputValue("");
        setTokenDataAddress(token.address);
      }

      hideDropdown();
    },
  }));

  const tokenSymbol = tokenData?.symbol ?? "-";

  return (
    <Dropdown
      align="right"
      hideDropdown={hideDropdown}
      isShowDropdown={isShowDropdown}
      options={options}
    >
      <DropdownButton onClick={toggleDropdown}>
        <Content>
          {tokenSymbol}
          <DropdownArrow />
        </Content>
      </DropdownButton>
    </Dropdown>
  );
};
