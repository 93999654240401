import { getZero } from "@roe-monorepo/shared-features/src/shared/helpers/bigjs";
import { getChainMetadata } from "@roe-monorepo/shared-features/src/web3/helpers/getChainMetadata";
import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import { useCallback } from "react";

import { BaseMmButton } from "../../shared/components/BaseMmButton";
import { useTokenApproveTransaction } from "../../shared/queries/hooks/useTokenApproveTransaction";
import { BaseMMButtonColor } from "../../shared/types/baseMmButtonProps";
import { useOpenPositionModalQueries } from "../hooks/useOpenPositionModalQueries";
import { useOpenPositionModalState } from "../hooks/useOpenPositionModalState";

export const ApproveMainButton = () => {
  const { sourceTokenData, inputValueBig } = useOpenPositionModalState();

  const tokenAddress = sourceTokenData?.address ?? "";

  const { openPositionTokenQuery } = useOpenPositionModalQueries();

  const { mutation, resetTransaction, runTransaction } =
    useTokenApproveTransaction(tokenAddress, [openPositionTokenQuery]);

  const { chainId = 1 } = useWallet();
  const { addresses } = getChainMetadata(chainId);
  const { roePm } = addresses;

  const handleButtonClick = useCallback(() => {
    runTransaction(roePm);
  }, [runTransaction, roePm]);

  const { isError, isLoading, error } = mutation;
  const { message = "Please, try again later" } = error ?? {};

  const isButtonDisabled = inputValueBig.lte(getZero());

  if (isError) {
    return (
      <BaseMmButton color={BaseMMButtonColor.RED} onClick={resetTransaction}>
        {message}
      </BaseMmButton>
    );
  }

  return (
    <BaseMmButton
      color={BaseMMButtonColor.WHITE}
      disabled={isButtonDisabled}
      isLoading={isLoading}
      onClick={handleButtonClick}
    >
      {isLoading ? "Approving..." : "Approve"}
    </BaseMmButton>
  );
};
