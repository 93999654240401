import tw from "twin.macro";

import { BaseIcon } from "../../shared/components/BaseIcon";

export const ChainIcon = tw(BaseIcon)`
  w-6
`;

export const Container = tw.div`
  w-full flex justify-center
`;
