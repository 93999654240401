import styled from "styled-components";
import tw from "twin.macro";

import type { PageBannerProps } from "../types/PageBannerProps";

type ContainerProps = Pick<PageBannerProps, "backgroundSrc">;

export const Container = styled.div<ContainerProps>`
  ${tw`
      flex items-center justify-between
      px-10 py-2
      rounded-lg
      bg-cover
      w-full
    `}

  background-image: url(${(props) => props.backgroundSrc});
`;

export const Content = tw.div`
  flex flex-col gap-4
`;

export const TitlesContainer = tw.div`
  flex flex-col
`;

export const Title = tw.span`
  text-lg text-white
  font-bold
`;

export const Link = styled.a.attrs({
  target: "_blank",
})`
  ${tw`
      text-white
      font-medium
      italic
    `}
`;

export const Icon = tw.img`
  w-44
`;
