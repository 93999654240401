import type { ReactNode } from "react";

export enum BaseMMButtonColor {
  BLUE = "blue",
  WHITE = "white",
  BRAND = "brand",
  GRAY = "gray",
  GREEN = "green",
  RED = "red",
  GRADIENT = "gradient",
}

export interface BaseMmButtonProps {
  color: BaseMMButtonColor;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
  children: ReactNode;
}
