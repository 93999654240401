import { useQuery } from "react-query";

import { openPositionInfoFetcher } from "../helpers/openPositionInfoFetcher";

import type { ModalState } from "../../modal/types/ModalState";
import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";
import type Big from "big.js";

export const useOpenPositionInfoQuery = (
  account: string,
  chainId: ChainId,
  address: string,
  lPoolAddress: string,
  pairRow: ModalState,
  blockNumber?: string,
  amount?: Big
) =>
  useQuery({
    queryKey: [
      account,
      chainId,
      address,
      lPoolAddress,
      blockNumber,
      pairRow,
      amount,
    ],

    queryFn: async () =>
      await openPositionInfoFetcher(
        account,
        chainId,
        address,
        lPoolAddress,
        pairRow,
        blockNumber,
        amount
      ),
  });
