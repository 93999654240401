import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

import { useShowOpenPositionModal } from "../../main-page/hooks/useOpenPositionModal";
import { useOpenPositionInfoQuery } from "../../openPosition/hooks/useOpenPositionInfoQuery";
import { useOpenPositionModalState } from "../../openPosition/hooks/useOpenPositionModalState";
import { getFormattedAPY } from "../helpers/formatters";
import { Container, Description, Step } from "../styles/FundingRateExplanation";

export const RunwayExplanation = () => {
  const { modalState } = useShowOpenPositionModal();

  const { tokens, collateralTokenIndex, swapFeesAPY } = modalState ?? {};

  const symbol = collateralTokenIndex ? tokens?.[1] : tokens?.[0];

  const { chainId = ChainId.MAINNET, account = "" } = useWallet();

  const lendingPoolAddress = modalState?.lendingPoolAddress ?? "";

  const assetOracle = modalState?.addresses?.assetOracle ?? "";

  const { inputValueBig } = useOpenPositionModalState();

  const { data } = useOpenPositionInfoQuery(
    account,
    chainId,
    String(assetOracle),
    String(lendingPoolAddress),
    modalState,
    undefined,
    inputValueBig
  );

  const { estimatedNewBorrowInterest, calculations } = data ?? {};

  const {
    stableSupplied,
    leverage,
    simulatedSupply,
    existingBorrow,
    simulatedBorrow,
    estimatedNewSupplyInterest,
    afterBorrowSupply,
    totalBorrow,
    pairTokenPrice,
    utilization,
    aTokenAddress,
    variableDebtTokenAddress,
    lpPair,
    interestRateStrategyAddress,
    softLiquidationPoint,
    liq,
    ltv,
    openingHealthFactor,
    timeLeftInDays,
  } = calculations ?? {
    stableSupplied: 0,
    leverage: 0,
    simulatedSupply: 0,
    existingBorrow: 0,
    simulatedBorrow: 0,
    estimatedNewSupplyInterest: 0,
    afterBorrowSupply: 0,
    totalBorrow: 0,
    pairTokenPrice: 0,
    utilization: 0,
    aTokenAddress: "",
    variableDebtTokenAddress: "",
    lpPair: "",
    interestRateStrategyAddress: "",
    softLiquidationPoint: 0,
    liq: 0,
    ltv: 0,
    openingHealthFactor: 0,
    timeLeftInDays: 0,
  };

  return (
    <Container>
      {/* eslint-disable-next-line eslint-comments/disable-enable-pair */}
      {/* eslint-disable @typescript-eslint/restrict-template-expressions */}
      <Step>{`${symbol} Supplied: ${stableSupplied}`}</Step>
      <Step>{`Leverage: ${leverage}`}</Step>

      <Description>
        # Current LP supply <br />
        simulatedSupply = AToken.at({`"${aTokenAddress}"`}).totalSupply()
      </Description>
      <Step>simulatedSupply = {simulatedSupply.toString()}</Step>

      <Description>
        # Current Debt supply
        <br />
        existingBorrow = VariableDebtToken.at({`"${variableDebtTokenAddress}"`}
        ).totalSupply()
      </Description>
      <Step>existingBorrow = {existingBorrow.toString()}</Step>

      <Description>
        # Get simulated LP borrow
        <br />
        simulatedBorrow = (USDC_Supplied * maxLev * 1e18 / priceOfLP) <br />
        simulatedBorrow ={" "}
        {`(${stableSupplied} * ${leverage} * 1e18 / ${pairTokenPrice})`}
        <br />
      </Description>
      <Step>simulatedBorrow = {simulatedBorrow.toFixed(0)}</Step>

      <Description>
        # Get debt supply after simulated borrow
        <br />
        totalBorrow = existingBorrow + simulatedBorrow <br />
        totalBorrow = {`${existingBorrow} + ${simulatedBorrow.toFixed(0)}`}
        <br />
      </Description>
      <Step>totalBorrow = {totalBorrow.toString()}</Step>

      <Description>
        # Get aTokenSupply after simulated borrow
        <br />
        afterBorrowSupply = simulatedSupply - totalBorrow <br />
        afterBorrowSupply = {`${simulatedSupply} - ${totalBorrow}`}
        <br />
      </Description>
      <Step>afterBorrowSupply = {afterBorrowSupply.toString()}</Step>

      <Description>
        # Get utilization
        <br />
        utilization = totalBorrow / simulatedSupply <br />
        utilization = {`${totalBorrow} - ${simulatedSupply}`}
        <br />
      </Description>
      <Step>utilization = {getFormattedAPY(utilization)}</Step>

      <Description>
        # Get new supply interest
        <br />
        ir = {interestRateStrategyAddress}
        <br />
        estimatedNewSupplyInterest = ir.calculateInterestRates(lpPair,
        afterBorrowSupply, 0, totalBorrow, 0, 0)[0]/1e27;
        <br />
        estimatedNewSupplyInterest = ir.calculateInterestRates
        {`(${lpPair}, ${afterBorrowSupply}, 0, ${totalBorrow}, 0, 0)[0]/1e27`}
        <br />
      </Description>
      <Step>estimatedNewSupplyInterest = {estimatedNewSupplyInterest}</Step>

      <Description>
        # Get new borrow interest
        <br />
        estimatedNewBorrowInterest = estimatedNewSupplyInterest / totalBorrow *
        simulatedSupply
        <br />
        estimatedNewBorrowInterest ={" "}
        {`${estimatedNewSupplyInterest} / ${totalBorrow} * ${simulatedSupply}`}
        <br />
      </Description>
      <Step>estimatedNewBorrowInterest = {estimatedNewBorrowInterest}</Step>

      <Description># Soft Liquidation Point</Description>
      <Step>{`softLiquidationPoint = ${softLiquidationPoint}`}</Step>

      <Description>
        # Health factor on open
        <br />
        openingHealthFactor = liq / ltv <br />
        openingHealthFactor = {`${liq} / ${ltv}`}
      </Description>
      <Step>{`openingHealthFactor = ${openingHealthFactor}`}</Step>

      <Description>
        # Get swap fees last 7 days APY
        <br />
      </Description>
      <Step>swapFeesAPY = {swapFeesAPY}</Step>

      <Description>
        # Runway in days
        <br />
        timeLeftInDays = 365 * (openingHealthFactor - 1.01) /
        (estimatedNewBorrowInterest + swapFeesAPY) <br />
        {`timeLeftInDays = 365 * (${openingHealthFactor} - ${softLiquidationPoint}) / (${estimatedNewBorrowInterest} + ${swapFeesAPY})`}
      </Description>
      <Step>{`timeLeftInDays = ${timeLeftInDays}`}</Step>
    </Container>
  );
};
