import { BaseIcon } from "@roe-monorepo/shared-features/src/shared/components/BaseIcon";
import { getExplorerLink } from "@roe-monorepo/shared-features/src/web3/helpers/getExplorerLink";
import { ExplorerLinkType } from "@roe-monorepo/shared-features/src/web3/types/ExplorerLinkType";

import { externalLink } from "../../icons/common";
import { useOpenPositionModalState } from "../hooks/useOpenPositionModalState";
import { useOpenPositionModalTransactions } from "../hooks/useOpenPositionModalTransactions";
import { Container, Link } from "../styles/OpenPositionTransactionLink";

import type { FC } from "react";

export const OpenPositionTransactionLink: FC = () => {
  const { pairData } = useOpenPositionModalState();
  const { transactionHash } = useOpenPositionModalTransactions();

  const chainId = pairData?.chainId;

  const link =
    chainId && transactionHash
      ? getExplorerLink(chainId, ExplorerLinkType.TX, transactionHash)
      : undefined;

  if (!transactionHash) {
    return null;
  }

  return (
    <Container>
      <Link href={link}>
        View in Explorer
        <BaseIcon src={externalLink} />
      </Link>
    </Container>
  );
};
