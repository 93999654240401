import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

export const Container = styled(motion.div)`
  ${tw`
        flex justify-center items-start flex-col
        w-full
        gap-2`}
`;

export const InfoContainer = styled(motion.div).attrs({
  initial: { height: 0 },
  animate: { height: "auto" },
})`
  ${tw`
  flex justify-center items-start flex-col
  bg-gray-newSemiLight
  w-full
  gap-2
  rounded-lg
`}
`;

export const Title = tw.h2`
  text-white
  text-lg
  font-semibold
  px-2
`;

export const BrandValue = tw.span`
  text-brand
  text-base
  font-semibold
`;
