import { getChainMetadata } from "@roe-monorepo/shared-features/src/web3/helpers/getChainMetadata";

import { useModal } from "../../modal/hooks/useModal";
import { usePairQuery } from "../../shared/queries/hooks/usePairQuery";
import { useTokenQuery } from "../../shared/queries/hooks/useTokenQuery";

import { useDelegatedTokenQuery } from "./useDelegatedTokenQuery";

export const useOpenPositionModalQueries = () => {
  const { modalState } = useModal();
  const { id = "" } = modalState ?? {};

  const pairQuery = usePairQuery(id);

  const pairData = pairQuery.data;
  const {
    collateral,
    chainId = 1,
    variableDebtTokenAddress = "",
  } = pairData ?? {};
  const { address = "" } = collateral ?? {};
  const { addresses } = getChainMetadata(chainId);
  const { roePm } = addresses;

  const openPositionTokenQuery = useTokenQuery({
    chainId,
    tokenAddress: address,
    spenderAddress: roePm,
  });

  const delegatedAllowanceTokenQuery = useDelegatedTokenQuery({
    chainId,

    tokenAddress: variableDebtTokenAddress,
    spenderAddress: roePm,
  });

  return {
    openPositionTokenQuery,
    delegatedAllowanceTokenQuery,
    pairData,
  };
};
