import styled from "styled-components";
import tw from "twin.macro";

import { BaseButton } from "../../shared/components/BaseButton";
import { BaseModal } from "../../shared/components/BaseModal";

export const Container = tw(BaseModal)`
  gap-4
  px-5
  py-4 max-w-[432px] w-full
`;

export const Header = tw.h1`
  flex justify-center
  text-lg text-white font-semibold
`;

export const Paragraph = tw.p`
  mb-4
`;

export const Footer = tw.div`
  font-medium
  text-xs text-white text-center px-3
`;

export const AgreeButton = styled(BaseButton)`
  ${tw`w-full bg-blue-light border-none hover:(text-white bg-blue)`}
  ${({ disabled }) => disabled && tw`bg-gray-newLight hover:(bg-gray-newLight)`}
`;

export const Content = styled.div`
  ${tw`max-h-[448px] h-full overflow-y-auto text-gray-newLight font-medium`}

  ul {
    ${tw`pl-5 pr-1`}

    li {
      ${tw`relative pb-4`}

      &::after {
        ${tw`content[""] w-[5px] h-[5px] bg-gray-newLight rounded-full absolute -left-4 top-[10px]`}
      }
    }
  }
`;
