/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable lines-around-comment */
import { useCallback, useState } from "react";
import { useMutation } from "react-query";

import { useWallet } from "../../web3/hooks/useWallet";

import type {
  DependantQueries,
  OnTransactionSuccess,
} from "../types/BaseTransaction";
import type { Contract, ContractTransaction } from "ethers";

export const useBaseTransaction = <
  TContract extends Contract,
  TMethod extends keyof TContract["functions"]
>(
  contract: TContract,
  method: TMethod,
  dependantQueries: DependantQueries = [],
  onTransactionSuccess: OnTransactionSuccess = () => undefined
) => {
  const [transactionHash, setTransactionHash] = useState<string>();
  const { provider } = useWallet();

  const mutationFunction = useCallback(
    async (methodParameters: Parameters<TContract["functions"][TMethod]>) => {
      if (!provider) {
        throw new Error("No provider");
      }

      const signer = provider.getSigner();
      const connectedContract = contract.connect(signer);

      // @ts-expect-error typings
      await connectedContract.callStatic[method](...methodParameters);

      let transaction: ContractTransaction | null = null;
      try {
        transaction =
          // @ts-expect-error typings
          await connectedContract.functions[method](...methodParameters);
      } catch (error) {
        const { code, message } = error as { code: number; message: string };

        if (code === 4001) {
          throw new Error("Transaction Cancelled");
        }

        throw new Error(message);
      }

      if (transaction) {
        const { hash } = transaction;

        setTransactionHash(hash);

        await transaction.wait();
      }
    },
    [contract, method, provider]
  );

  const handleMutationSuccess = useCallback(async () => {
    const dependantQueriesPromises = dependantQueries.map(
      async (query) => await query.refetch()
    );

    await Promise.all(dependantQueriesPromises);
    await onTransactionSuccess();
  }, [dependantQueries, onTransactionSuccess]);

  const mutation = useMutation<
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    void,
    Error,
    Parameters<TContract["functions"][TMethod]>
  >(mutationFunction, {
    onSuccess: handleMutationSuccess,

    onError: () => {
      setTimeout(() => {
        mutation.reset();
      }, 2000);
    },
  });

  const resetTransaction = useCallback(() => {
    setTransactionHash(undefined);
    mutation.reset();
  }, [mutation]);

  return {
    mutation,
    resetTransaction,
    transactionHash,
  };
};
