import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

interface ContainerProps {
  minWidth: number;
  topPosition: number;
  rightPosition: string;
}

export const Container = styled(motion.div).attrs(() => ({
  initial: {
    scale: 0,
  },

  animate: {
    scale: 1,
  },

  exit: { scale: 0 },
}))<ContainerProps>`
  top: ${({ topPosition }) => topPosition}px;
  right: ${({ rightPosition }) => rightPosition};
  min-width: ${({ minWidth }) => minWidth}px;

  ${tw`
    absolute
    w-max
  `}
`;
export const Content = tw.div`
  flex flex-col
  bg-gray-dark
  rounded-lg
  overflow-hidden
  border-2
  border-gray-newSemiLight
`;
