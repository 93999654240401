import { useCallback } from "react";

import { useModal } from "../../modal/hooks/useModal";
import { useWallet } from "../../web3/hooks/useWallet";
import { walletButtonConfigs } from "../constants/walletButtonConfigs";
import { Button, Container, Content, WalletIcon } from "../styles/WalletButton";

import type { WalletType } from "../../web3/types/WalletType";
import type { FC } from "react";

interface WalletButtonProps {
  walletType: WalletType;
}

export const WalletButton: FC<WalletButtonProps> = ({ walletType }) => {
  const { connect } = useWallet();
  const { popModal } = useModal();

  const connectWallet = useCallback(() => {
    void (async () => {
      await connect(walletType);

      popModal();
    })();
  }, [connect, walletType, popModal]);

  const { title, iconSource } = walletButtonConfigs[walletType];

  return (
    <Button onClick={connectWallet}>
      <Container>
        <Content>
          <WalletIcon src={iconSource} />
          {title}
        </Content>
      </Container>
    </Button>
  );
};
