import makeBlockie from "ethereum-blockies-base64";
import { AnimatePresence } from "framer-motion";
import { useOutsideClick } from "rooks";

import { copy, cross, externalLink } from "../../icons/utils";
import { getTruncatedAddress } from "../../web3/helpers/addresses";
import { getExplorerLink } from "../../web3/helpers/getExplorerLink";
import { useWallet } from "../../web3/hooks/useWallet";
import { ChainId } from "../../web3/types/ChainId";
import { ExplorerLinkType } from "../../web3/types/ExplorerLinkType";
import {
  AddressAvatar,
  AddressContainer,
  CardContainer,
  CardContent,
  CardHeading,
  ChangeButton,
  CloseButton,
  CloseButtonIcon,
  ConnectedButtonsContainer,
  ConnectedContainer,
  CopyButton,
  DisconnectButton,
  ExplorerButton,
  HelpButtonIcon,
  HelpButtonsContainer,
} from "../styles/AccountCard";

import type { FC, MutableRefObject } from "react";

export interface AccountCardContentDto {
  isShow: boolean;
  parentReference: MutableRefObject<HTMLDivElement | null>;
  onHide: () => void;
  disconnect: () => void;
  changeProvider: () => void;
}

export const AccountCardContent: FC<AccountCardContentDto> = ({
  isShow,
  parentReference,
  onHide,
  disconnect,
  changeProvider,
}) => {
  useOutsideClick(parentReference, onHide);

  const { account = "", chainId = ChainId.MAINNET } = useWallet();

  const truncatedAccount = account ? getTruncatedAddress(account) : null;

  const avatar = makeBlockie(account);

  const explorerLink = getExplorerLink(
    chainId,
    ExplorerLinkType.ADDRESS,
    account
  );

  const copyAddress = () => {
    void navigator.clipboard.writeText(account);
  };

  return (
    <AnimatePresence>
      {isShow ? (
        <CardContainer>
          <CardHeading>
            Account
            <CloseButton onClick={onHide}>
              <CloseButtonIcon src={cross} />
            </CloseButton>
          </CardHeading>
          <CardContent>
            <ConnectedContainer>
              Connected
              <ConnectedButtonsContainer>
                <DisconnectButton onClick={disconnect}>
                  Disconnect
                </DisconnectButton>
                <ChangeButton onClick={changeProvider}>Change</ChangeButton>
              </ConnectedButtonsContainer>
            </ConnectedContainer>
            <AddressContainer>
              <AddressAvatar src={avatar} />
              {truncatedAccount}
            </AddressContainer>
            <HelpButtonsContainer>
              <CopyButton onClick={copyAddress}>
                <HelpButtonIcon src={copy} />
                Copy address
              </CopyButton>
              <ExplorerButton href={explorerLink} target="_blank">
                <HelpButtonIcon src={externalLink} />
                View on explorer
              </ExplorerButton>
            </HelpButtonsContainer>
          </CardContent>
        </CardContainer>
      ) : null}
    </AnimatePresence>
  );
};
