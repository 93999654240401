import { TinyInfoCard } from "@roe-monorepo/shared-features/src/main-page/components/TinyInfoCard";

import { money, chart } from "../../icons/common";
import { usePositionInfoData } from "../hooks/usePositionInfoData";
import { Container } from "../styles/PositionsInfo";

export const PositionsInfo = () => {
  const { balance = "N/A", pnl = "N/A" } = usePositionInfoData();

  return (
    <Container>
      <TinyInfoCard
        icon={money}
        prefix="$"
        title="Total Position"
        value={balance}
      />
      <TinyInfoCard icon={chart} prefix="$" title="PNL" value={pnl} />
    </Container>
  );
};
