import { ContentContainer, Title } from "../styles/HeaderButton";

import type { FC } from "react";

interface Props {
  title: string;
}

export const Header: FC<Props> = ({ title }) => (
  <ContentContainer>
    <Title>{title}</Title>
  </ContentContainer>
);
