import styled from "styled-components";
import tw from "twin.macro";

export const HeaderCell = styled.th.withConfig({
  shouldForwardProp: (property, defaultValidatorFunction) =>
    ["align"].includes(property) || defaultValidatorFunction(property),
})`
  ${tw`
    p-3
    whitespace-nowrap
    first:pl-6
    last:pr-6
  `}
`;

export const HeaderRow = tw.tr`
  bg-gray-dark
`;

export const Cell = styled.td.withConfig({
  shouldForwardProp: (property, defaultValidatorFunction) =>
    ["align"].includes(property) || defaultValidatorFunction(property),
})`
  ${tw`
    p-3
    font-semibold
    text-white text-sm
    whitespace-nowrap
    first:(pl-6 w-full)
    last:pr-6
  `}
`;

export const Row = tw.tr`
  bg-gray-dark
  border-t border-gray-semiLight
  cursor-pointer
  transition
  hover:bg-gray
`;
