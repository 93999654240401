import {
  getZero,
  toBig,
} from "@roe-monorepo/shared-features/src/shared/helpers/bigjs";
import { createContext, useMemo, useState } from "react";

import { useOpenPositionModalQueries } from "../hooks/useOpenPositionModalQueries";

import type { OpenPositionModalContextState } from "../types/OpenPositionModalContextState";
import type { FC, ReactNode } from "react";

interface OpenPositionModalStateProviderProps {
  children: ReactNode;
}

export const OpenPositionModalStateContext =
  createContext<OpenPositionModalContextState>({
    pairData: undefined,
    sourceTokenData: undefined,
    delegatedTokenData: undefined,
    inputValue: "",
    setInputValue: () => undefined,
    inputValueBig: getZero(),
    isInsufficientBalance: false,
  });

export const OpenPositionModalStateProvider: FC<
  OpenPositionModalStateProviderProps
> = ({ children }) => {
  const [inputValue, setInputValue] = useState("");
  const { openPositionTokenQuery, delegatedAllowanceTokenQuery, pairData } =
    useOpenPositionModalQueries();

  const sourceTokenData = openPositionTokenQuery.data;
  const delegatedTokenData = delegatedAllowanceTokenQuery.data;

  const inputValueBig = inputValue ? toBig(inputValue) : getZero();

  const isInsufficientBalance =
    sourceTokenData?.balance && inputValueBig.gt(0)
      ? sourceTokenData.balance.lt(inputValueBig)
      : false;

  const value = useMemo(
    () => ({
      sourceTokenData,
      delegatedTokenData,
      inputValue,
      setInputValue,
      inputValueBig,
      isInsufficientBalance,
      pairData,
    }),
    [
      sourceTokenData,
      delegatedTokenData,
      inputValue,
      setInputValue,
      inputValueBig,
      isInsufficientBalance,
      pairData,
    ]
  );

  return (
    <OpenPositionModalStateContext.Provider value={value}>
      {children}
    </OpenPositionModalStateContext.Provider>
  );
};
