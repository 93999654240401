import { PairChainIcon } from "@roe-monorepo/shared-features/src/main-page/components/PairChainIcon";
import { toBig } from "@roe-monorepo/shared-features/src/shared/helpers/bigjs";
import { Table } from "@roe-monorepo/shared-features/src/table/components/Table";
import { Tooltip } from "@roe-monorepo/shared-features/src/tooltip/components/Tooltip";
import { useCallback } from "react";

import { getFormattedAPY } from "../../shared/helpers/formatters";
import { useAssetsTableRows } from "../hooks/useAssetsTableRows";
import { useClosePositionModal } from "../hooks/useClosePositionModal";
import { useShowOpenPositionModal } from "../hooks/useOpenPositionModal";
import { Container } from "../styles/AssetsTable";
import { SypplyApyCell } from "../styles/PositionsTable";

import { SupplyAPYTooltip } from "./SupplyApyTooltip";
import { TitleCell } from "./TitleCell";

import type { PairRow } from "@roe-monorepo/shared-features/src/shared/types/PairRow";
import type { Column } from "@roe-monorepo/shared-features/src/table/types/Column";

interface Position extends PairRow {
  id: string;
}

const columns: Column<Position>[] = [
  {
    key: "title",
    title: "Assets",

    render: ({ title, tokens }) => <TitleCell title={title} tokens={tokens} />,
  },
  {
    key: "chainId",
    title: "Network",

    render: ({ chainId }) => <PairChainIcon chainId={chainId} />,
  },
  {
    key: "id",
    title: "Collateral",
    render: ({ collateral }) => collateral.symbol,
  },
  {
    key: "swapFeesAPY",
    title: "Funding Rate",

    render: ({ source, swapFeesAPY, apy }) => {
      const supplyAPY = toBig(String(swapFeesAPY)).add(apy).toNumber();

      return (
        <Tooltip
          content={
            <SupplyAPYTooltip
              apy={apy}
              source={source}
              swapFeesAPY={Number(swapFeesAPY)}
            />
          }
        >
          <SypplyApyCell>{getFormattedAPY(supplyAPY)}</SypplyApyCell>
        </Tooltip>
      );
    },
  },
  {
    key: "booster",
    title: "Leverage",
    render: ({ leverage }) => (leverage ? `x${leverage}` : "N/A"),
  },
];

const getRowKey = (row: Position) => row.id;

export const AssetsTable = () => {
  const rows = useAssetsTableRows().filter(
    (row: PairRow | undefined) =>
      !row || !row.supplyValue || Number(row.supplyValue) === 0
  );

  const { showOpenPositionModal } = useShowOpenPositionModal();
  const { showClosePositionModal } = useClosePositionModal();

  const handleRowClick = useCallback(
    (row: PairRow) => {
      if (Number(row.supplyValue) > 0) {
        showClosePositionModal(row);
      } else {
        showOpenPositionModal(row);
      }
    },
    [showOpenPositionModal, showClosePositionModal]
  );

  return (
    <Container>
      <Table
        columns={columns}
        getRowKey={getRowKey}
        onRowClick={handleRowClick}
        rows={rows}
      />
    </Container>
  );
};
