import { BaseIcon } from "@roe-monorepo/shared-features/src/shared/components/BaseIcon";
import tw from "twin.macro";

export const Title = tw.span`
  text-base
`;

export const Content = tw.div`
  flex items-center gap-2 bg-gray p-2 px-3 rounded-lg border-2 border-gray-semiLight
`;

export const Icon = tw(BaseIcon)`
  w-5
`;
