import { Container, Title } from "../styles/Positions";

import { PositionsInfo } from "./PositionsInfo";
import { PositionsTable } from "./PositionsTable";

export const Positions = () => (
  <Container>
    <Title>Your Positions</Title>
    <PositionsInfo />
    <PositionsTable />
  </Container>
);
