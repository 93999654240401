import { useCallback } from "react";

import { useClosePositionModal } from "../../main-page/hooks/useClosePositionModal";
import { usePairQueries } from "../../main-page/hooks/usePairQueries";
import { BaseMmButton } from "../../shared/components/BaseMmButton";
import { BaseMMButtonColor } from "../../shared/types/baseMmButtonProps";
import { useClosePositionModalState } from "../hooks/useClosePositionModalState";
import { useClosePositionModalTransactions } from "../hooks/useClosePositionModalTransactions";

export const CloseTradeMainButton = () => {
  const { runTransaction } = useClosePositionModalTransactions();

  const { sourceTokenData } = useClosePositionModalState();

  const { modalState } = useClosePositionModal();

  const { poolId = 0 } = modalState ?? {};

  const { pairTokenQuery } = usePairQueries();
  const pairTokenData = pairTokenQuery.data;

  const handleButtonClick = useCallback(() => {
    if (sourceTokenData && pairTokenData) {
      runTransaction(poolId, sourceTokenData.address, pairTokenData.address);
    }
  }, [pairTokenData, runTransaction, sourceTokenData, poolId]);

  return (
    <BaseMmButton color={BaseMMButtonColor.GREEN} onClick={handleButtonClick}>
      Close Trade
    </BaseMmButton>
  );
};
