import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

import { TitleCell } from "../../main-page/components/TitleCell";
import { useModal } from "../../modal/hooks/useModal";
import { ChainTitle } from "../../shared/components/ChainTitle";
import { ModalTextExplanation } from "../../shared/components/ModalTextExplanation";
import { ClosePositionModalStateProvider } from "../providers/ClosePositionModalStateProvider";
import { ClosePositionModalTransactionsProvider } from "../providers/ClosePositionModalTransactionsProvider";
import { Container, Title, Wrapper } from "../styles/closePositionModal";

import { ClosePositionTransactionLink } from "./ClosePositionTransactionLink";
import { CloseTradeInfo } from "./CloseTradeInfo";
import { CloseTradePositionInfo } from "./CloseTradePositionInfo";
import { MainButton } from "./MainButton";

export const ClosePositionModal = () => {
  const { chainId = ChainId.MAINNET } = useWallet();

  const { modalState } = useModal();

  const {
    title = "-",
    tokens = ["-", "-"],
    chainId: pairChainId = ChainId.MAINNET,
  } = modalState ?? {};

  const infoVisible = pairChainId === chainId;

  return (
    <ClosePositionModalTransactionsProvider>
      <ClosePositionModalStateProvider>
        <Wrapper>
          <Container>
            <Title>
              <TitleCell title={title} tokens={tokens} />
              <ChainTitle chainId={pairChainId} />
            </Title>
            <ModalTextExplanation />
            <MainButton />
            <ClosePositionTransactionLink />
            {infoVisible ? (
              <>
                <CloseTradeInfo />
                <CloseTradePositionInfo />
              </>
            ) : null}
          </Container>
        </Wrapper>
      </ClosePositionModalStateProvider>
    </ClosePositionModalTransactionsProvider>
  );
};
