import { links } from "@roe-monorepo/shared-features/src/shared/constants/links";

import { BaseMmButton } from "../../shared/components/BaseMmButton";
import { BaseMMButtonColor } from "../../shared/types/baseMmButtonProps";
import { Link } from "../styles/InsufficientLiquidityButton";

export const InsufficientLiquidityButton = () => (
  <Link href={links.discord} rel="noreferrer" target="_blank">
    <BaseMmButton color={BaseMMButtonColor.RED}>
      Out of Liquidity - Please Contact Discord
    </BaseMmButton>
  </Link>
);
