import { getDefaultProvider } from "ethers";

import { RoePM__factory as RoePMFactory } from "../../../smart-contracts/types";

import { fetchChainExplorerData } from "./fetchChainExplorerData";

import type { OpenPositionEvent } from "../../../smart-contracts/types/RoePM";
import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

export const openPositionEventFetcher = async (
  account: string,
  asset: string,
  contractAddress: string,
  chainId: ChainId
) => {
  const roePMContract = RoePMFactory.connect(
    contractAddress,
    getDefaultProvider()
  );

  const roePmInterface = RoePMFactory.createInterface();

  const { address: filterAddress, topics: filterTopics } =
    // eslint-disable-next-line new-cap
    roePMContract.filters.OpenPosition(account, asset, null, null);

  try {
    const responseData = await fetchChainExplorerData(
      chainId,
      filterAddress,
      filterTopics
    );

    const { result } = responseData;

    const lastTransaction =
      result.length > 0 ? result[result.length - 1] : undefined;

    if (lastTransaction) {
      const { data, topics, blockNumber } = lastTransaction;

      const parsedTransaction = roePmInterface.decodeEventLog(
        roePmInterface.getEvent("OpenPosition"),
        data,
        topics
      ) as unknown as OpenPositionEvent["args"];

      return {
        ...parsedTransaction,
        blockNumber,
      };
    }

    return undefined;
  } catch {
    return undefined;
  }
};
