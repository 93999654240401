import { useCallback, useState } from "react";

import type { DropdownState } from "../types/DropdownState";

export const useDropdown = (): DropdownState => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  const toggleDropdown = useCallback(() => {
    setIsShowDropdown((previousIsShowDropdown) => !previousIsShowDropdown);
  }, []);

  const hideDropdown = useCallback(() => {
    setIsShowDropdown(false);
  }, []);

  return {
    isShowDropdown,
    toggleDropdown,
    hideDropdown,
  };
};
