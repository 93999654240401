import { getImageSourceByChainId } from "../../icons/helpers/getImageSourceByChainId";
import { ChainIcon, Container } from "../styles/PairChainIcon";

import type { PairRow } from "../../shared/types/PairRow";
import type { FC } from "react";

type PairChainIconProps = Pick<PairRow, "chainId">;

export const PairChainIcon: FC<PairChainIconProps> = ({ chainId }) => {
  const iconSource = getImageSourceByChainId(chainId);

  return iconSource ? (
    <Container>
      <ChainIcon src={iconSource} />
    </Container>
  ) : null;
};
