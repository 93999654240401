import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

export const Container = tw.div`
    relative
`;

export const CardHeading = tw.div`
    flex
    justify-between
    text-white
    text-sm
    font-medium
`;

export const CloseButton = tw.button``;

export const CloseButtonIcon = tw.img``;

export const CardContainer = styled(motion.div).attrs(() => ({
  initial: {
    scale: 0,
  },

  animate: {
    scale: 1,
  },

  exit: { scale: 0 },
}))`
  ${tw`
    flex
    flex-col
    bg-gray-dark
    border-2
    border-gray-newSemiLight
    absolute
    rounded-lg
    w-80
    px-6
    py-3
    right-0
    mt-2
    gap-4
    `}
`;

export const CardContent = tw.div`
    flex
    flex-col
    border
    border-white
    rounded-lg
    p-2
    gap-2
`;

export const ConnectedContainer = tw.div`
    flex
    items-center
    justify-between
    text-gray-light
    text-xs
    font-medium
`;

export const ConnectedButtonsContainer = tw.div`
    flex
    gap-2
`;

export const HelpButtonsContainer = tw.div`
    flex
    gap-5
`;

export const HelpButtonIcon = tw.img``;

export const DisconnectButton = tw.button`
    px-1.5
    border
    border-blue-light
    text-blue-light
    rounded-lg
    text-xs
    font-medium
`;

export const ChangeButton = tw.button`
    px-1.5
    border
    border-white
    text-white
    rounded-lg
    text-xs
    font-medium
`;

export const CopyButton = tw.button`
    flex
    items-center
    text-xs
    font-medium
    text-blue-light
    gap-1
`;

export const ExplorerButton = tw.a`
    flex
    items-center
    text-xs
    font-medium
    text-white
    gap-1
`;

export const AddressContainer = tw.div`
    flex
    items-center
    gap-2
    text-white
    text-base
    font-medium
`;

export const AddressAvatar = tw.img`
    w-4
    h-4
    rounded-lg
`;
