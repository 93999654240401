import tw from "twin.macro";

export const Container = tw.div`
  flex flex-col justify-center items-center
  bg-gray-dark
  w-full
  rounded-lg
  gap-2
  border
  border-gray-newSemiLight
`;

export const Title = tw.h1`
  text-white
  text-xl
  font-semibold
  p-8
  w-full
  text-left
`;
