import {
  getExp,
  toBig,
} from "@roe-monorepo/shared-features/src/shared/helpers/bigjs";
import { getChainConfig } from "@roe-monorepo/shared-features/src/web3/helpers/getChainConfig";
import { getProvider } from "@roe-monorepo/shared-features/src/web3/helpers/getProvider";
import { BigNumber, getDefaultProvider } from "ethers";
import { formatUnits } from "ethers/lib/utils";

import {
  CLAgg__factory as CLAggFactory,
  LendingPool__factory as LendingPool,
  IERC20__factory as Token,
  ILendingPoolAddressesProvider__factory as Ir,
} from "../../smart-contracts/types";

import type { ModalState } from "../../modal/types/ModalState";
import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";
import type Big from "big.js";

export const openPositionInfoFetcher = async (
  account: string,
  chainId: ChainId,
  oracleAddress: string,
  lPoolAddress: string,
  pairRow: ModalState,
  blockNumber?: string,
  amount?: Big
) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const provider = getProvider(chainId) ?? getDefaultProvider();

  const mainAssetOracle = String(
    getChainConfig(chainId).addresses.mainAssetOracleAddress
  );

  const gasPrice = await provider
    .getGasPrice()
    .then((weiValue) => toBig(formatUnits(weiValue, "ether")));

  const transactionGasLimit = 1_500_000;

  const simulatedGasSpend = gasPrice.mul(transactionGasLimit);

  const feeTokenOracle = CLAggFactory.connect(mainAssetOracle, provider);

  const tokenOracle = CLAggFactory.connect(oracleAddress, provider);

  const lendingPool = LendingPool.connect(lPoolAddress, provider);

  const feeTokenPrice = await feeTokenOracle
    .latestAnswer()
    .then(toBig)
    .then((price) => price.div(getExp(8)));

  const transactionFee = feeTokenPrice.mul(simulatedGasSpend);

  const priceOfAsset = await tokenOracle
    .latestAnswer()
    .then(toBig)
    .then((price) => price.div(getExp(8)));

  const initialAssetPrice = await tokenOracle
    .latestAnswer({
      blockTag: blockNumber,
    })
    .then(toBig)
    .then((price) => price.div(getExp(8)));

  const rl = await lendingPool.getReservesList();

  // eslint-disable-next-line prefer-destructuring
  const lpPair = rl[2];

  const { data } = await lendingPool.getConfiguration(lpPair);

  const ltv = toBig(data).mod(2 ** 16);

  const liq = toBig(
    // eslint-disable-next-line no-bitwise
    toBig(data)
      .mod(2 ** 32)
      .toNumber() >> 16
  );

  const ltvPercentage = ltv.div(10_000);

  const leverage = toBig("1")
    .div(toBig("1").sub(ltvPercentage))
    .sub(1)
    .round(0);

  const reserveData = await lendingPool.getReserveData(lpPair);

  const {
    interestRateStrategyAddress,
    variableDebtTokenAddress,
    aTokenAddress,
  } = reserveData;

  const ir = Ir.connect(interestRateStrategyAddress, provider);

  const {
    pairTokenPrice = "1",
    initialDeposit = "1",
    initialPairDebt = 0,
    swapFeesAPY = 0,
  } = pairRow ?? {};

  const aToken = Token.connect(aTokenAddress, provider);

  const debtToken = Token.connect(variableDebtTokenAddress, provider);

  const debtTokenBalance = await debtToken.balanceOf(account).then(toBig);

  const simulatedSupply = await aToken.totalSupply().then(toBig);

  const existingBorrow = await debtToken.totalSupply().then(toBig);

  const simulatedBorrow = (amount?.gt(0) ? amount : toBig(initialDeposit))
    .mul(leverage)
    .mul(getExp(18))
    .div(pairTokenPrice);

  const totalBorrow = existingBorrow.add(simulatedBorrow).toFixed(0);

  const afterBorrowSupply = simulatedSupply.sub(totalBorrow).toFixed(0);

  let calculatedInterestRates = [BigNumber.from(1)];

  let outOfLiquidity = false;

  try {
    calculatedInterestRates = await ir[
      "calculateInterestRates(address,uint256,uint256,uint256,uint256,uint256)"
    ](lpPair, afterBorrowSupply, 0, totalBorrow, 0, 0);
  } catch {
    outOfLiquidity = true;
  }

  const estimatedNewSupplyInterest = toBig(calculatedInterestRates[0]).div(
    getExp(27)
  );

  const estimatedNewBorrowInterest = existingBorrow.gt(0)
    ? estimatedNewSupplyInterest
        .div(existingBorrow.add(simulatedBorrow))
        .mul(simulatedSupply)
    : toBig(0);

  const utilization = simulatedSupply.gt(0)
    ? existingBorrow.add(simulatedBorrow).div(simulatedSupply)
    : toBig(0);

  const softLiquidationPoint = toBig("1.01");

  const openingHealthFactor = liq.div(ltv);

  const fundingRate = estimatedNewBorrowInterest.add(swapFeesAPY);

  const timeLeftInDays = toBig("365")
    .mul(openingHealthFactor.sub(softLiquidationPoint))
    .div(fundingRate);

  const profit = debtTokenBalance.sub(initialPairDebt).div(pairTokenPrice);

  const priceChange = priceOfAsset.div(initialAssetPrice).minus(1);

  return {
    transactionFee: transactionFee.toFixed(2),
    price: priceOfAsset.toFixed(2),
    initialPrice: initialAssetPrice.toFixed(2),
    priceChange: priceChange.toNumber(),
    leverage: leverage.toFixed(0),
    fundingRate: fundingRate.toNumber(),
    runway: timeLeftInDays.toNumber(),
    profit: profit.toFixed(2),
    healthFactor: openingHealthFactor.toFixed(4),
    estimatedNewBorrowInterest: estimatedNewBorrowInterest.toFixed(6),
    outOfLiquidity,

    calculations: {
      stableSupplied: amount?.gt(0) ? amount : toBig(initialDeposit),
      leverage,
      simulatedSupply,
      existingBorrow,
      simulatedBorrow,
      estimatedNewSupplyInterest: estimatedNewSupplyInterest.toNumber(),
      pairTokenPrice,
      afterBorrowSupply,
      totalBorrow,
      utilization: utilization.toNumber(),
      aTokenAddress,
      variableDebtTokenAddress,
      lpPair,
      interestRateStrategyAddress,

      softLiquidationPoint,
      liq,
      ltv,
      openingHealthFactor,
      timeLeftInDays,

      debtTokenBalance,
      initialPairDebt,
    },
  };
};
