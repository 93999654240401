import { links } from "@roe-monorepo/shared-features/src/shared/constants/links";

import {
  SubTitle,
  SubTitleLink,
} from "../../openPosition/styles/OpenPositionModal";

export const ModalTextExplanation = () => (
  <SubTitle>
    Supply collateral to leverage 10x to take on a impermanent gains position.
    Close the position to lock in your profit. Your cost is the interest fees.
    Soft liquidation occurs when your supply goes is below 1.01. Hard
    liquidation is at 1. We strongly advise you use the profit calculator to
    understand your potential P&L outcomes before you open a position.&nbsp;
    <SubTitleLink href={links.tradeProfitCalculator} target="_blank">
      Profit Calculator
    </SubTitleLink>
  </SubTitle>
);
