import { getFormattedPairTitle } from "../../pair/helpers/getFormattedPairTitle";

import { getExp, toBig } from "./bigjs";

import type { Pair } from "../../queries/types/Pair";
import type { Token } from "../../queries/types/Token";
import type { PairRow } from "../types/PairRow";
import type { UseQueryResult } from "react-query";

export const toPairRow = (
  pair: Pair,
  walletPairToken: UseQueryResult<Token>["data"],
  walletAToken: UseQueryResult<Token>["data"]
): PairRow => {
  const {
    id,
    chainId,
    source,
    token0,
    token1,
    collateral,
    poolId,
    collateralTokenIndex,
    aToken,
    apy,
    swapFeesAPY,
    booster,
    pairTokenPrice,
    accountData,
    leverage,
    liquidationCoefficient,
    blockNumber,
    lendingPoolAddress,
    addresses,
  } = pair;

  const tokens: PairRow["tokens"] = [token0.symbol, token1.symbol];
  const title = getFormattedPairTitle(pair);
  const totalValueLocked = aToken.totalSupply.mul(pairTokenPrice).toNumber();
  const pairTokenBalance = walletPairToken?.balance;
  const aTokenBalance = walletAToken?.balance;

  const supplyValue = accountData?.totalDebtETH
    ? toBig(accountData.totalDebtETH).div(1e8).toFixed(2)
    : undefined;

  const healthFactorString = accountData?.healthFactor
    ? accountData.healthFactor.toString()
    : undefined;

  const healthFactor = healthFactorString
    ? `${healthFactorString.slice(0, 1)}.${healthFactorString.slice(1, 5)}`
    : undefined;

  const pnl =
    accountData?.totalDebtETH &&
    accountData.totalCollateralETH &&
    accountData.initialDeposit
      ? toBig(accountData.totalCollateralETH)
          .sub(toBig(accountData.totalDebtETH))
          .div(1e8)
          .sub(accountData.initialDeposit)
          .toFixed(2)
      : undefined;

  const initialPairDebt = accountData?.initialPairDebt
    ? accountData.initialPairDebt.div(getExp(18)).toNumber()
    : undefined;

  return {
    id,
    chainId,
    source,
    tokens,
    collateral,
    poolId,
    collateralTokenIndex,
    title,
    totalValueLocked,
    apy,
    swapFeesAPY,
    booster,
    pairTokenBalance,
    aTokenBalance,
    blockNumber,
    pairTokenPrice,
    leverage: leverage.toFixed(0),

    liquidationCoefficient,

    liquidationValue: supplyValue
      ? liquidationCoefficient.mul(supplyValue).toFixed(2)
      : "0",

    healthFactor,
    supplyValue,
    initialDeposit: accountData?.initialDeposit?.toFixed(2),
    initialDebt: accountData?.initialDebt?.toFixed(2),
    currentDebt: accountData?.currentDebt?.toFixed(2),
    pnl: Number(pnl) === 0 ? "0" : pnl,
    initialPairDebt,

    lendingPoolAddress,
    addresses,
    accountData,
  };
};
