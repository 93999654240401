import { Dropdown } from "../../dropdown/components/Dropdown";
import { useDropdown } from "../../dropdown/hooks/useDropdown";
import {
  book,
  discord,
  medium,
  policies,
  terms,
  twitter,
} from "../../icons/social";
import { links } from "../../shared/constants/links";
import { Button } from "../styles/MenuButton";

const options = [
  {
    title: "Twitter",
    link: links.twitter,
    icon: twitter,
  },
  {
    title: "Discord",
    link: links.discord,
    icon: discord,
  },
  {
    title: "Medium",
    link: links.medium,
    icon: medium,
  },
  {
    title: "Docs",
    link: links.gitBook,
    icon: book,
  },
  {
    title: "Privacy Policies",
    link: links.privacyPolicy,
    icon: policies,
  },
  {
    title: "Legal & Terms",
    link: links.termsAndConditions,
    icon: terms,
  },
];

export const MenuButton = () => {
  const { isShowDropdown, toggleDropdown, hideDropdown } = useDropdown();

  return (
    <Dropdown
      align="right"
      hideDropdown={hideDropdown}
      isShowDropdown={isShowDropdown}
      options={options}
    >
      <Button onClick={toggleDropdown}>⋯</Button>
    </Dropdown>
  );
};
