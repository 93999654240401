import { useModal } from "../../modal/hooks/useModal";
import { BaseMmButton } from "../../shared/components/BaseMmButton";
import { BaseMMButtonColor } from "../../shared/types/baseMmButtonProps";

export const SuccessfulMainButton = () => {
  const { hideModal } = useModal();

  return (
    <BaseMmButton
      color={BaseMMButtonColor.GRADIENT}
      onClick={() => {
        hideModal();
      }}
    >
      Open Trade Successful
    </BaseMmButton>
  );
};
