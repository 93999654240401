import tw from "twin.macro";

export const Container = tw.div`
  flex justify-center items-start flex-col
  w-[448px]
  max-w-[448px]
  gap-4
  p-6
`;

export const Wrapper = tw.div`
    flex flex-col 
  overflow-y-auto
  overflow-x-visible
  bg-gray-dark
  rounded-lg
  border
  border-gray-newSemiLight
`;

export const Title = tw.div`
  flex justify-between items-center flex-row
  w-full
  text-xl
  text-white
  font-semibold
  px-2
  gap-4
`;

export const SubTitle = tw.h4`
  text-sm
  text-white
  font-normal
`;

export const SubTitleLink = tw.a`
  text-blue
  underline
`;
