import { getPairConfig } from "@roe-monorepo/shared-features/src/pair/helpers/getPairConfig";
import { useQueries } from "react-query";

import { getPairQueryOptions } from "../helpers/queriesOptions";

export const usePairsQueries = (pairIds: string[], account?: string) => {
  const queriesOptions = pairIds.map((pairId) => {
    const { id, chainId } = getPairConfig(pairId);

    return getPairQueryOptions(id, chainId, account);
  });

  return useQueries(queriesOptions);
};
