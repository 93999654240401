import {
  Container,
  SwitcherActiveOption,
  SwitcherOption,
} from "../styles/ProductSwitcher";

import type { ProductSwitcherProps } from "../types/ProductSwitcherProps";
import type { FC } from "react";

export const ProductSwitcher: FC<ProductSwitcherProps> = ({ options }) => (
  <Container>
    {options.map(({ title, href, isActive }) =>
      isActive ? (
        <SwitcherActiveOption href={href} key={title}>
          {title}
        </SwitcherActiveOption>
      ) : (
        <SwitcherOption href={href} key={title}>
          {title}
        </SwitcherOption>
      )
    )}
  </Container>
);
