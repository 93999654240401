import { useCallback } from "react";

import { wallet } from "../../icons/wallet";
import { getZero } from "../../shared/helpers/bigjs";
import { getFormattedTokenBalance } from "../../web3/helpers/getFormattedTokenBalance";
import { toInputValueBig } from "../helpers/toInputValueBig";
import {
  BalanceContainer,
  BalanceValue,
  Container,
  Input,
  InputContainer,
  MaxButton,
  TokenSymbol,
  WalletIcon,
} from "../styles/InputCard";

import { TokensDropdown } from "./TokensDropdown";

import type { TokenData } from "../../queries/types/Token";
import type { ChangeEvent, FC } from "react";

export interface InputCardProps {
  tokenData: TokenData;
  tokens?: TokenData[];
  setTokenDataAddress?: (nextTokenDataAddress: string) => void;
  inputValue?: string;
  setInputValue: (nextInputValue: string) => void;
  isError: boolean;
  maxLength?: number;
}

export const InputCard: FC<InputCardProps> = ({
  tokenData,
  tokens,
  setTokenDataAddress,
  inputValue,
  setInputValue,
  isError,
  maxLength,
}) => {
  const tokenSymbol = tokenData?.symbol ?? "-";
  const tokenBalance = tokenData?.balance ?? getZero();

  const formattedTokenBalance = getFormattedTokenBalance(tokenData);
  const inputValueBig = toInputValueBig(inputValue ?? "0");

  const isShowTokensDropdown = Array.isArray(tokens) && tokens.length > 1;
  const isShowMaxButton =
    !tokenBalance.eq(inputValueBig) && tokenBalance.gt(getZero());

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (maxLength && event.target.value.length > maxLength) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = event.target.value.slice(0, maxLength);
      }
      setInputValue(event.target.value);
    },
    [setInputValue, maxLength]
  );

  const handleMaxButtonClick = useCallback(() => {
    if (tokenData?.balance) {
      setInputValue(tokenData.balance.toString());
    }
  }, [tokenData, setInputValue]);

  return (
    <Container>
      <InputContainer>
        <Input
          isError={isError}
          onChange={handleInputChange}
          value={inputValue}
        />
        {isShowTokensDropdown ? (
          <TokensDropdown
            setInputValue={setInputValue}
            setTokenDataAddress={setTokenDataAddress}
            tokenData={tokenData}
            tokens={tokens}
          />
        ) : (
          <TokenSymbol>{tokenSymbol}</TokenSymbol>
        )}
      </InputContainer>
      <BalanceContainer>
        <WalletIcon src={wallet} />
        <BalanceValue>{formattedTokenBalance}</BalanceValue>
        {isShowMaxButton ? (
          <MaxButton onClick={handleMaxButtonClick}>Max</MaxButton>
        ) : null}
      </BalanceContainer>
    </Container>
  );
};
