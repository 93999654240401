import { getPairConfig } from "@roe-monorepo/shared-features/src/pair/helpers/getPairConfig";
import { getPairsSwapFeesQueryOptions } from "@roe-monorepo/shared-features/src/queries/helpers/getPairsSwapFeesQueryOptions";
import {
  getExp,
  toBig,
} from "@roe-monorepo/shared-features/src/shared/helpers/bigjs";
import { getChainMetadata } from "@roe-monorepo/shared-features/src/web3/helpers/getChainMetadata";
import { getProvider } from "@roe-monorepo/shared-features/src/web3/helpers/getProvider";
import { BigNumber } from "ethers";

import {
  IUniswapV2Pair__factory as PairFactory,
  IAaveLendingPoolAddressesProviderRegistry__factory as LendingPoolAddressesProviderRegistryFactory,
  IAaveLendingPoolAddressesProvider__factory as LendingPoolAddressesProviderFactory,
  IAaveLendingPool__factory as LendingPoolFactory,
  IAavePriceOracle__factory as AavePriceOracleFactory,
  IChainLinkPriceOracle__factory as ChainLinkPriceOracleFactory,
} from "../../../smart-contracts/types";
import { queryClient } from "../../constants/queryClient";

import { openPositionEventFetcher } from "./openPositionEventFetcher";
// eslint-disable-next-line import/no-cycle
import { getTokenQueryOptions } from "./queriesOptions";

import type { Pair } from "../types/Pair";
import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

// eslint-disable-next-line complexity
export const pairFetcher = async (
  id: string,
  chainId: ChainId,
  account?: string
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Promise<Pair> => {
  const {
    source,
    pairAddress,
    pairIdInAddressesProviderRegistry,
    addresses,
    poolId,
    collateralTokenIndex,
  } = getPairConfig(id);
  const {
    addresses: { lendingPoolAddressesProviderRegistryAddress, roePm },
  } = getChainMetadata(chainId);
  const provider = getProvider(chainId);

  const pairContract = PairFactory.connect(pairAddress, provider);
  const lendingPoolAddressesProviderRegistryContract =
    LendingPoolAddressesProviderRegistryFactory.connect(
      lendingPoolAddressesProviderRegistryAddress,
      provider
    );

  const [token0Address, token1Address, addressesProvidersList] =
    await Promise.all([
      pairContract.token0(),
      pairContract.token1(),
      lendingPoolAddressesProviderRegistryContract.getAddressesProvidersList(),
    ]);

  // getting pair's addressesProviderAddress
  // by pairIdInAddressesProviderRegistry
  const addressesProviderAddress =
    addressesProvidersList[pairIdInAddressesProviderRegistry];

  const addressesProviderContract = LendingPoolAddressesProviderFactory.connect(
    addressesProviderAddress,
    provider
  );

  const [lendingPoolAddress, aavePriceOracleAddress, token0, token1] =
    await Promise.all([
      addressesProviderContract.getLendingPool(),
      addressesProviderContract.getPriceOracle(),
      queryClient.fetchQuery(getTokenQueryOptions(chainId, token0Address)),
      queryClient.fetchQuery(getTokenQueryOptions(chainId, token1Address)),
    ]);

  const [addressesProviderId, pairsSwapFees] = await Promise.all([
    lendingPoolAddressesProviderRegistryContract
      .getAddressesProviderIdByAddress(addressesProviderAddress)
      .then((value) => toBig(value).toNumber()),
    queryClient.fetchQuery(getPairsSwapFeesQueryOptions()),
  ]);

  const pairSwapFees =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    pairsSwapFees.chains[chainId]?.markets[addressesProviderId] ?? null;

  const swapFeesAPY = pairSwapFees ? pairSwapFees[7] : 0;

  const lendingPoolContract = LendingPoolFactory.connect(
    lendingPoolAddress,
    provider
  );

  const aavePriceOracleContract = AavePriceOracleFactory.connect(
    aavePriceOracleAddress,
    provider
  );

  // eslint-disable-next-line @typescript-eslint/init-declarations
  let accountData;

  try {
    if (account) {
      accountData = await lendingPoolContract.getUserAccountData(account);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
  }

  const {
    availableBorrowsETH,
    currentLiquidationThreshold,
    healthFactor,
    ltv,
    totalCollateralETH,
    totalDebtETH,
  } = accountData ?? {
    availableBorrowsETH: undefined,
    currentLiquidationThreshold: undefined,
    healthFactor: undefined,
    ltv: undefined,
    totalCollateralETH: undefined,
    totalDebtETH: undefined,
  };

  const [
    chainLinkPriceOracleAddress,
    { aTokenAddress, variableDebtTokenAddress, currentVariableBorrowRate },
  ] = await Promise.all([
    aavePriceOracleContract.getSourceOfAsset(pairAddress),
    lendingPoolContract.getReserveData(pairAddress),
  ]);

  const chainLinkPriceOracleContract = ChainLinkPriceOracleFactory.connect(
    chainLinkPriceOracleAddress,
    provider
  );

  const [rawPairTokenPrice, aToken] = await Promise.all([
    chainLinkPriceOracleContract.latestAnswer().then(toBig),
    queryClient.fetchQuery(getTokenQueryOptions(chainId, aTokenAddress)),
  ]);

  const apy = toBig(currentVariableBorrowRate).div(getExp(27)).toNumber();

  // TODO: replace with real value
  const booster = 0.2;

  const pairTokenPrice = rawPairTokenPrice.div(getExp(8)).toNumber();

  const rl = await lendingPoolContract.getReservesList();

  const { data: calculatedLtv = BigNumber.from("0") } =
    await lendingPoolContract.getConfiguration(rl[2]);

  const ltvPercentage = toBig(calculatedLtv)
    .mod(2 ** 16)
    .div(10_000);

  const liquidationCoefficient = toBig(
    // eslint-disable-next-line no-bitwise
    toBig(calculatedLtv)
      .mod(2 ** 32)
      .toNumber() >> 16
  ).div(10_000);

  const leverage = toBig("1")
    .div(toBig("1").sub(ltvPercentage))
    .sub(1)
    .round(0);

  const openPositionEventData = account
    ? await openPositionEventFetcher(account, pairAddress, roePm, chainId)
    : { value: undefined, amount: undefined, blockNumber: undefined };

  const {
    value: eventValue,
    blockNumber,
    amount,
  } = openPositionEventData ?? {};

  const initialDebt = toBig(eventValue ?? 0).div(1e8);

  const initialDeposit = initialDebt.div(leverage);

  const currentDebt = toBig(totalCollateralETH ?? 0)
    .sub(toBig(totalDebtETH ?? 0))
    .div(1e8);

  const initialPairDebt = toBig(amount ?? 0).mul(getExp(18));

  return {
    id,
    chainId,
    source,
    token0,
    token1,
    poolId,
    collateral: collateralTokenIndex ? token1 : token0,
    collateralTokenIndex,
    aToken,
    apy,
    booster,
    pairTokenPrice,
    lendingPoolAddress,
    leverage,
    liquidationCoefficient,
    blockNumber,
    addresses,
    swapFeesAPY,

    accountData: {
      availableBorrowsETH,
      currentLiquidationThreshold,
      healthFactor,
      ltv,
      totalCollateralETH,
      totalDebtETH,

      initialDeposit,
      initialDebt,
      currentDebt,

      initialPairDebt,
      openPositionEventData,
    },

    variableDebtTokenAddress,
  };
};
