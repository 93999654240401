import { Container, Title } from "../styles/Assets";

import { AssetsTable } from "./AssetsTable";

export const Assets = () => (
  <Container>
    <Title>Assets to Trade</Title>
    <AssetsTable />
  </Container>
);
