import { InputCard } from "@roe-monorepo/shared-features/src/input-card/components/InputCard";
import { useState } from "react";
import { debounceTime, Subject } from "rxjs";

import { useOpenPositionModalState } from "../hooks/useOpenPositionModalState";
import { Container } from "../styles/OpenPositionInputCard";

export const OpenPositionInputCard = () => {
  const [input, setInput] = useState("");
  const { setInputValue, isInsufficientBalance, sourceTokenData } =
    useOpenPositionModalState();
  const inputEvent$ = new Subject<string>();

  inputEvent$.pipe(debounceTime(300)).subscribe((value: string) => {
    setInputValue(value);
  });

  const setInputValueDebounce = (value: string) => {
    setInput(value);
    inputEvent$.next(value);
  };

  return (
    <Container>
      <InputCard
        inputValue={input}
        isError={isInsufficientBalance}
        maxLength={24}
        setInputValue={setInputValueDebounce}
        tokenData={sourceTokenData}
      />
    </Container>
  );
};
