import tw from "twin.macro";

export const InfoValue = tw.span`
  text-white
  text-base
  font-semibold
`;

export const BrandValue = tw.span`
  text-brand
  text-base
  font-semibold
`;
