import { useCallback } from "react";

import { useShowOpenPositionModal } from "../../main-page/hooks/useOpenPositionModal";
import { usePairQueries } from "../../main-page/hooks/usePairQueries";
import { BaseMmButton } from "../../shared/components/BaseMmButton";
import { BaseMMButtonColor } from "../../shared/types/baseMmButtonProps";
import { getTokenAmount } from "../helpers/getTokenAmount";
import { useOpenPositionModalState } from "../hooks/useOpenPositionModalState";
import { useOpenPositionModalTransactions } from "../hooks/useOpenPositionModalTransactions";

export const OpenTradeMainButton = () => {
  const { runTransaction } = useOpenPositionModalTransactions();

  const { sourceTokenData, inputValueBig, inputValue } =
    useOpenPositionModalState();

  const { pairTokenQuery } = usePairQueries();
  const pairTokenData = pairTokenQuery.data;

  const { modalState } = useShowOpenPositionModal();

  const { poolId = 0 } = modalState ?? {};

  const handleButtonClick = useCallback(async () => {
    if (sourceTokenData && pairTokenData) {
      await runTransaction(
        poolId,
        sourceTokenData.address,
        getTokenAmount(inputValueBig, sourceTokenData),
        pairTokenData.address,
        inputValue
      );
    }
  }, [
    pairTokenData,
    runTransaction,
    sourceTokenData,
    inputValueBig,
    poolId,
    inputValue,
  ]);

  return (
    <BaseMmButton
      color={BaseMMButtonColor.GREEN}
      onClick={() => {
        void handleButtonClick();
      }}
    >
      Open Trade
    </BaseMmButton>
  );
};
