import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";

import { useModal } from "../../modal/hooks/useModal";
import { useOpenPositionInfoQuery } from "../hooks/useOpenPositionInfoQuery";
import { useOpenPositionModalState } from "../hooks/useOpenPositionModalState";
import { useOpenPositionModalTransactions } from "../hooks/useOpenPositionModalTransactions";

import { ApproveDelegatedMainButton } from "./ApproveDelegatedMainButton";
import { ApproveMainButton } from "./ApproveMainButton";
import { ConnectWalletMainButton } from "./ConnectWalletMainButton";
import { ErrorMainButton } from "./ErrorMainButton";
import { InsufficientBalanceMainButton } from "./InsufficientBalanceMainButton";
import { InsufficientLiquidityButton } from "./InsufficientLiquidityButton";
import { LoadingMainButton } from "./LoadingMainButton";
import { OpenTradeMainButton } from "./OpenTradeMainButton";
import { SuccessfulMainButton } from "./SuccessfulMainButton";
import { WrongNetworkMainButton } from "./WrongNetworkMainButton";
import { ZeroBalanceMainButton } from "./ZeroBalanceMainButton";

// eslint-disable-next-line complexity,sonarjs/cognitive-complexity
export const MainButton = () => {
  const { isConnected, chainId = 1, account = "" } = useWallet();
  const { modalState } = useModal();
  const {
    isInsufficientBalance,
    inputValueBig,
    sourceTokenData,
    delegatedTokenData,
  } = useOpenPositionModalState();

  const { chainId: pairChainId = 1 } = modalState ?? {};

  const lendingPoolAddress = modalState?.lendingPoolAddress ?? "";

  const assetOracle = modalState?.addresses?.assetOracle ?? "";

  const { data } = useOpenPositionInfoQuery(
    account,
    chainId,
    String(assetOracle),
    String(lendingPoolAddress),
    modalState,
    undefined,
    inputValueBig
  );

  const { outOfLiquidity = false } = data ?? {};

  const { mutation } = useOpenPositionModalTransactions();

  const { isSuccess, isLoading, isError } = mutation;

  if (!isConnected) {
    return <ConnectWalletMainButton />;
  }

  if (pairChainId !== chainId) {
    return <WrongNetworkMainButton />;
  }

  if (isLoading) {
    return <LoadingMainButton />;
  }

  if (isSuccess) {
    return <SuccessfulMainButton />;
  }

  if (isError) {
    return <ErrorMainButton />;
  }

  if (inputValueBig.lte(0)) {
    return <ZeroBalanceMainButton />;
  }

  if (outOfLiquidity) {
    return <InsufficientLiquidityButton />;
  }

  if (isInsufficientBalance) {
    return <InsufficientBalanceMainButton />;
  }

  if (sourceTokenData?.allowance.lt(inputValueBig)) {
    return <ApproveMainButton />;
  }

  if (delegatedTokenData?.allowance.lt(inputValueBig)) {
    return <ApproveDelegatedMainButton />;
  }

  return <OpenTradeMainButton />;
};
