import styled from "styled-components";
import tw from "twin.macro";

import { BaseButton } from "../../shared/components/BaseButton";

interface TriangleContainerProps {
  isShow: boolean;
  isUp: boolean;
}

export const Button = tw(BaseButton)`
  px-0 py-0
  text-xs
  border-none
`;

export const ContentContainer = tw.div`
  flex flex-row gap-1
`;

export const Title = tw.span`
  text-xs
  text-white
`;

export const TriangleContainer = styled.span<TriangleContainerProps>`
  ${tw`transition-transform`}
  ${({ isShow }) => (isShow ? tw`opacity-100` : tw`opacity-0`)}
  ${({ isUp }) => (isUp ? tw`rotate-0` : tw`rotate-180`)}
`;
