import { QueryType } from "../types/QueryType";

// eslint-disable-next-line import/no-cycle
import { pairFetcher } from "./pairFetcher";
import { tokenFetcher } from "./tokenFetcher";

import type { Pair } from "../types/Pair";
import type { Token } from "../types/Token";
import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";
import type { UseQueryOptions } from "react-query/types/react/types";

export const getPairQueryOptions = (
  id: string,
  chainId: ChainId,
  account?: string
): UseQueryOptions<Pair> => ({
  queryKey: [QueryType.PAIR, id, chainId, account],
  queryFn: async () => await pairFetcher(id, chainId, account),
  staleTime: Number.POSITIVE_INFINITY,
});

export const getTokenQueryOptions = (
  chainId: ChainId,
  tokenAddress: string | null,
  spenderAddress?: string,
  account?: string
): UseQueryOptions<Token> => ({
  queryKey: [QueryType.TOKEN, chainId, tokenAddress, spenderAddress, account],

  queryFn: async () =>
    await tokenFetcher(chainId, tokenAddress ?? "", spenderAddress, account),

  enabled: Boolean(tokenAddress),
});
