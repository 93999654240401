import { useAssetsTableRows } from "./useAssetsTableRows";

import type { PairRow } from "@roe-monorepo/shared-features/src/shared/types/PairRow";

export const usePositionsTableRows = () => {
  const rows = useAssetsTableRows();

  return rows.filter(
    (row: PairRow | undefined) => !row || Number(row.supplyValue) > 0
  );
};
