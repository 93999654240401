import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";

import { walletConnectors } from "../constants/walletConnectors";
import { getChainParameters } from "../helpers/getChainParameters";

import { useLastConnectedWalletType } from "./useLastConnectedWalletType";

import type { ChainId } from "../types/ChainId";
import type { WalletType } from "../types/WalletType";

export const useWallet = () => {
  const {
    connector: connectedConnector,
    provider,
    chainId,
    account,
    isActivating: isConnecting,
    isActive: isConnected,
  } = useWeb3React();
  const { setLastWalletType, removeLastWalletType } =
    useLastConnectedWalletType();

  const connect = useCallback(
    async (walletType: WalletType) => {
      const [connector] = walletConnectors[walletType];

      await connector.activate();

      setLastWalletType(walletType);
    },
    [setLastWalletType]
  );

  const disconnect = useCallback(() => {
    void (async () => {
      await connectedConnector.deactivate();

      removeLastWalletType();
    })();
  }, [connectedConnector, removeLastWalletType]);

  const switchChainId = useCallback(
    async (nextChainId: ChainId) => {
      const chainParameters = getChainParameters(nextChainId);

      await connectedConnector.activate(chainParameters);
    },
    [connectedConnector]
  );

  return {
    account,
    chainId,
    provider,
    isConnected,
    isConnecting,
    switchChainId,
    connect,
    disconnect,
  };
};
