import { Button, Content, Icon, Link } from "../styles/DropdownOption";

import type { DropdownOptionConfig } from "../types/DropdownOptionConfig";
import type { FC } from "react";

export const DropdownOption: FC<DropdownOptionConfig> = ({
  title,
  icon,
  link,
  onClick,
}) => (
  <Link href={link} rel="noreferrer" target="_blank">
    <Button onClick={onClick}>
      <Content>
        {title}
        {icon ? <Icon src={icon} /> : null}
      </Content>
    </Button>
  </Link>
);
