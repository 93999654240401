import { BaseIcon } from "@roe-monorepo/shared-features/src/shared/components/BaseIcon";
import tw from "twin.macro";

export const Container = tw.div`
  flex justify-center items-center flex-col
  gap-2
  w-full
  max-w-[864px]
`;

export const TextContainer = tw.div`
  flex justify-center items-center flex-col
  gap-4
`;

export const Title = tw.h1`
  text-white
  text-3xl
  font-medium
`;

export const SubTitle = tw.h4`
  text-white
  text-base
  font-normal
  pb-5
`;

export const SubTitleLink = tw.a`
  text-blue
  text-base
  font-normal
`;

export const LegalIcon = tw(BaseIcon)`
  pb-8
`;
