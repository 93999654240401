import { Dropdown } from "../../dropdown/components/Dropdown";
import { useDropdown } from "../../dropdown/hooks/useDropdown";
import { getImageSourceByChainId } from "../../icons/helpers/getImageSourceByChainId";
import { getChainConfig } from "../helpers/getChainConfig";
import { useWallet } from "../hooks/useWallet";

import { ChainButton } from "./ChainButton";

import type { ChainId } from "../types/ChainId";
import type { FC } from "react";

interface SwitchChainButtonProps {
  supportedChainIds: ChainId[];
}

export const SwitchChainButton: FC<SwitchChainButtonProps> = ({
  supportedChainIds,
}) => {
  const { chainId: walletChainId, switchChainId } = useWallet();
  const { isShowDropdown, toggleDropdown, hideDropdown } = useDropdown();

  const supportedChainConfigs = supportedChainIds.map((chainId) =>
    getChainConfig(chainId)
  );

  const filteredChainConfigs = supportedChainConfigs.filter(
    ({ chainId }) => chainId !== walletChainId
  );

  const options = filteredChainConfigs.map(({ chainId, title }) => ({
    title,
    icon: getImageSourceByChainId(chainId),

    onClick: async () => {
      await switchChainId(chainId);
      hideDropdown();
    },
  }));

  return (
    <Dropdown
      hideDropdown={hideDropdown}
      isShowDropdown={isShowDropdown}
      options={options}
    >
      <ChainButton
        onClick={toggleDropdown}
        supportedChainIds={supportedChainIds}
      />
    </Dropdown>
  );
};
