import { BaseIcon } from "@roe-monorepo/shared-features/src/shared/components/BaseIcon";
import { getExplorerLink } from "@roe-monorepo/shared-features/src/web3/helpers/getExplorerLink";
import { ExplorerLinkType } from "@roe-monorepo/shared-features/src/web3/types/ExplorerLinkType";

import { externalLink } from "../../icons/common";
import { useClosePositionModalState } from "../hooks/useClosePositionModalState";
import { useClosePositionModalTransactions } from "../hooks/useClosePositionModalTransactions";
import { Container, Link } from "../styles/ClosePositionTransactionLink";

import type { FC } from "react";

export const ClosePositionTransactionLink: FC = () => {
  const { pairData } = useClosePositionModalState();
  const { transactionHash } = useClosePositionModalTransactions();

  const chainId = pairData?.chainId;

  const link =
    chainId && transactionHash
      ? getExplorerLink(chainId, ExplorerLinkType.TX, transactionHash)
      : undefined;

  if (!transactionHash) {
    return null;
  }

  return (
    <Container>
      <Link href={link}>
        View in Explorer
        <BaseIcon src={externalLink} />
      </Link>
    </Container>
  );
};
