import { ChainId } from "../../web3/types/ChainId";
import { PairSource } from "../types/PairSource";

import type { PairConfig } from "../types/PairConfig";

export const pairConfigs: PairConfig[] = [
  {
    id: "USDC-WETH-UNISWAPV2",
    chainId: ChainId.MAINNET,
    source: PairSource.UNISWAPV2,
    pairAddress: "0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc",
    pairIdInAddressesProviderRegistry: 0,
    collateralTokenIndex: 0,
    poolId: 0,

    addresses: {
      lPoolApAddress: "0x01b76559D512Fa28aCc03630E8954405BcBB1E02",
      qsOracle: "0x0D1f22002e8B75CfF0F3f0A04BdF543ED043F6CC",
      assetOracle: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
    },
  },
  {
    id: "WBTC-USDC-UNISWAPV2",
    chainId: ChainId.MAINNET,
    source: PairSource.UNISWAPV2,
    pairAddress: "0x004375dff511095cc5a197a54140a24efef3a416",
    pairIdInAddressesProviderRegistry: 4,
    collateralTokenIndex: 1,
    poolId: 1,

    addresses: {
      lPoolApAddress: "0xCBc54aFbDCf9FE5dacA9980b26fEB8c3c0d14a08",
      qsOracle: "0x849AF4b128be3317a694bFD262dEFF636AB84c1b",
      assetOracle: "0xF4030086522a5bEEa4988F8cA5B36dbC97BeE88c",
    },
  },
  {
    id: "FXS-FRAX-UNISWAPV2",
    chainId: ChainId.MAINNET,
    source: PairSource.UNISWAPV2,
    pairAddress: "0xe1573b9d29e2183b1af0e743dc2754979a40d237",
    pairIdInAddressesProviderRegistry: 2,
    collateralTokenIndex: 1,
    poolId: 2,

    addresses: {
      lPoolApAddress: "0x1286A774322bB1153750f8a4Eb19Bab3a1343F69",
      qsOracle: "0xf97A1A905Ac35D364456dD173553131FB9039570",
      assetOracle: "0x6Ebc52C8C1089be9eB3945C4350B68B8E4C2233f",
    },
  },
  {
    id: "USDC-WETH-QUICKSWAP",
    chainId: ChainId.POLYGON,
    source: PairSource.QUICKSWAP,
    pairAddress: "0x853ee4b2a13f8a742d64c8f088be7ba2131f670d",
    pairIdInAddressesProviderRegistry: 0,
    collateralTokenIndex: 0,
    poolId: 0,

    addresses: {
      lPoolApAddress: "0x9545bc5708ad2cd5D584465068412F2445018d6f",
      qsOracle: "0xD31750152eb8C9C46Fe00e8e3546750E975FD079",
      assetOracle: "0xF9680D99D6C9589e2a93a78A04A279e509205945",
    },
  },
  {
    id: "USDC-WETH-ARB!",
    chainId: ChainId.ARBITRUM,
    source: PairSource.SUSHISWAP,
    pairAddress: "0x905dfcd5649217c42684f23958568e533c711aa3",
    pairIdInAddressesProviderRegistry: 0,
    collateralTokenIndex: 1,
    poolId: 0,

    addresses: {
      lPoolApAddress: "0x0029B254d039d8C5C88512a44EAa6FF999296009",
      qsOracle: "0x429aF81694078Fda879Aa6147bb107a81374Fc77",
      assetOracle: "0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612",
    },
  },

  // {
  //   id: "GMX-WETH-ARB!",
  //   chainId: ChainId.ARBITRUM,
  //   source: PairSource.SUSHISWAP,
  //   pairAddress: "0x05c6f695ad50c16299bedca3fe9059b56550082f",
  //   pairIdInAddressesProviderRegistry: 1,
  //   collateralTokenIndex: 0,
  //
  //   addresses: {
  //     lPoolApAddress: "0xC41CB15EB8B99E9Af87572828600e055e526cfdf",
  //     qsOracle: "0x2C1BAe7739A137C20d1B16b169beE4B9E3540035",
  //     assetOracle: "0xdb98056fecfff59d032ab628337a4887110df3db",
  //   },
  // },
];
