import tw from "twin.macro";

export const Container = tw.span`
  flex-col
  text-black
  font-semibold
  bg-white
`;

export const Step = tw.div`
  p-1
  text-white
  font-light
  text-base
`;

export const Description = tw.div`
  p-1
  text-gray-light
  font-light
  text-sm
`;
