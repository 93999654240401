import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

import { useShowOpenPositionModal } from "../../main-page/hooks/useOpenPositionModal";
import { DevelopmentExplanation } from "../../shared/components/DevelopmentExplanation";
import { FundingRateExplanation } from "../../shared/components/FundingRateExplanation";
import { Runway } from "../../shared/components/Runway";
import { RunwayExplanation } from "../../shared/components/RunwayExplanation";
import {
  getFormattedAPY,
  getFormattedCurrency,
} from "../../shared/helpers/formatters";
import { useOpenPositionInfoQuery } from "../hooks/useOpenPositionInfoQuery";
import { useOpenPositionModalState } from "../hooks/useOpenPositionModalState";
import { Container, Title, InfoContainer } from "../styles/OpenTradeInfo";

import { InfoRow } from "./InfoRow";

// eslint-disable-next-line complexity
export const OpenTradeInfo = () => {
  const { modalState } = useShowOpenPositionModal();

  const {
    tokens,
    collateralTokenIndex,
    chainId: pairChainId,
  } = modalState ?? {};

  const symbol = collateralTokenIndex ? tokens?.[0] : tokens?.[1];

  const { chainId = ChainId.MAINNET, isConnected, account = "" } = useWallet();

  const lendingPoolAddress = modalState?.lendingPoolAddress ?? "";

  const assetOracle = modalState?.addresses?.assetOracle ?? "";

  const { inputValueBig } = useOpenPositionModalState();

  const { data, isLoading } = useOpenPositionInfoQuery(
    account,
    chainId,
    String(assetOracle),
    String(lendingPoolAddress),
    modalState,
    undefined,
    inputValueBig
  );

  const {
    price = "0",
    leverage = "0",
    runway = 0,
    healthFactor = "1.0000",
    outOfLiquidity = false,
    fundingRate = 0,
  } = data ?? {};

  const visible =
    inputValueBig.gt(0) &&
    isConnected &&
    chainId === pairChainId &&
    !outOfLiquidity;

  const position = inputValueBig.mul(leverage);

  return visible ? (
    <Container>
      <Title>Order Information</Title>
      <InfoContainer>
        <InfoRow
          isLoading={isLoading}
          title={`Entry Price of ${symbol ?? "..."}`}
          value={getFormattedCurrency(Number(price), {
            maximumFractionDigits: 2,
          })}
        />
        <InfoRow
          isLoading={isLoading}
          title="Collateral Supplied"
          value={getFormattedCurrency(Number(inputValueBig.toNumber()), {
            maximumFractionDigits: 2,
          })}
        />
        <InfoRow
          isLoading={isLoading}
          title="Leverage"
          value={`${leverage}x`}
        />
        <InfoRow
          isLoading={isLoading}
          title="Position"
          value={getFormattedCurrency(Number(position), {
            maximumFractionDigits: 2,
          })}
        />
        <InfoRow
          isLoading={isLoading}
          title="Funding Rate"
          value={
            <DevelopmentExplanation explanation={<FundingRateExplanation />}>
              <>{String(getFormattedAPY(fundingRate))}</>
            </DevelopmentExplanation>
          }
        />
        <InfoRow
          isLoading={isLoading}
          title="Health Factor"
          value={String(healthFactor)}
        />
        <InfoRow
          isLoading={isLoading}
          title="Estimated Runway"
          value={
            <DevelopmentExplanation explanation={<RunwayExplanation />}>
              <Runway runway={runway} />
            </DevelopmentExplanation>
          }
        />
      </InfoContainer>
    </Container>
  ) : null;
};
