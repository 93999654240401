import { Container } from "../styles/MainPage";

import { Assets } from "./Assets";
import { Banner } from "./Banner";
import { Positions } from "./Positions";

export const MainPage = () => (
  <Container>
    <Banner />
    <Positions />
    <Assets />
  </Container>
);
