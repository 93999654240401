import { BrandValue, InfoValue } from "../styles/Runway";

export const Runway = ({ runway }: { runway: number }) => {
  const secondsInMinute = 60;
  const secondsInHour = secondsInMinute * 60;
  const secondsInDay = secondsInHour * 24;

  let remainder = Math.floor(runway * secondsInDay);

  const daysValue = Math.floor(remainder / secondsInDay);
  remainder %= secondsInDay;

  const hoursValue = Math.floor(remainder / secondsInHour);

  return (
    <InfoValue>
      {daysValue} <BrandValue>D</BrandValue> {hoursValue}{" "}
      <BrandValue>H</BrandValue>
    </InfoValue>
  );
};
