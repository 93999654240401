import { delegatedTokenFetcher } from "./delegatedTokenFetcher";

import type { Token } from "../../shared/queries/types/Token";
import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";
import type { UseQueryOptions } from "react-query";

export const getDelegatedTokenQueryOptions = (
  chainId: ChainId,
  tokenAddress: string | null,
  spenderAddress?: string,
  account?: string
): UseQueryOptions<Token> => ({
  queryKey: [chainId, tokenAddress, spenderAddress, account],

  queryFn: async () =>
    await delegatedTokenFetcher(
      chainId,
      tokenAddress ?? "",
      spenderAddress,
      account
    ),

  enabled: Boolean(tokenAddress),
});
