import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";

import { useModal } from "../../modal/hooks/useModal";
import { ConnectWalletMainButton } from "../../openPosition/components/ConnectWalletMainButton";
import { useClosePositionModalTransactions } from "../hooks/useClosePositionModalTransactions";

import { CloseTradeMainButton } from "./CloseTradeMainButton";
import { ErrorMainButton } from "./ErrorMainButton";
import { LoadingMainButton } from "./LoadingMainButton";
import { SuccessfulMainButton } from "./SuccessfulMainButton";
import { WrongNetworkMainButton } from "./WrongNetworkMainButton";

export const MainButton = () => {
  const { isConnected, chainId } = useWallet();
  const { modalState } = useModal();

  const { chainId: pairChainId = 1 } = modalState ?? {};

  const { mutation } = useClosePositionModalTransactions();

  const { isSuccess, isLoading, isError } = mutation;

  if (!isConnected) {
    return <ConnectWalletMainButton />;
  }

  if (pairChainId !== chainId) {
    return <WrongNetworkMainButton />;
  }

  if (isLoading) {
    return <LoadingMainButton />;
  }

  if (isSuccess) {
    return <SuccessfulMainButton />;
  }

  if (isError) {
    return <ErrorMainButton />;
  }

  return <CloseTradeMainButton />;
};
