import { useCallback } from "react";

import { openPositionModalType } from "../../modal/constants/openPositionModalType";
import { useModal } from "../../modal/hooks/useModal";

import type { PairRow } from "@roe-monorepo/shared-features/src/shared/types/PairRow";

export const useShowOpenPositionModal = () => {
  const { pushModal, modalState } = useModal();

  const showOpenPositionModal = useCallback(
    (pair: PairRow) => {
      pushModal(openPositionModalType, pair);
    },
    [pushModal]
  );

  return { showOpenPositionModal, modalState };
};
