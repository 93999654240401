import makeBlockie from "ethereum-blockies-base64";
import { useCallback } from "react";

import { AccountCard } from "../../account-card/components/AccountCard";
import { connectWalletModalType } from "../../connect-wallet-modal/constants/connectWalletModalType";
import { DropdownArrow } from "../../dropdown/components/DropdownArrow";
import { useDropdown } from "../../dropdown/hooks/useDropdown";
import { useModal } from "../../modal/hooks/useModal";
import { BaseButton } from "../../shared/components/BaseButton";
import { getTruncatedAddress } from "../helpers/addresses";
import { useWallet } from "../hooks/useWallet";
import {
  AddressAvatar,
  BaseConnectWalletButton,
  WalletConnectedButtonContent,
} from "../styles/ConnectWalletButton";

export const ConnectWalletButton = () => {
  const { isConnected, disconnect, account } = useWallet();
  const { pushModal } = useModal();

  const { isShowDropdown, toggleDropdown, hideDropdown } = useDropdown();

  const showConnectWalletModal = useCallback(() => {
    pushModal(connectWalletModalType);
  }, [pushModal]);

  const changeProvider = useCallback(() => {
    disconnect();
    showConnectWalletModal();
  }, [disconnect, showConnectWalletModal]);

  const truncatedAccount = account ? getTruncatedAddress(account) : null;

  const avatar = account ? makeBlockie(account) : "";

  return isConnected ? (
    <AccountCard
      changeProvider={changeProvider}
      disconnect={disconnect}
      hideDropdown={hideDropdown}
      isShowDropdown={isShowDropdown}
    >
      <BaseButton onClick={toggleDropdown}>
        <WalletConnectedButtonContent>
          <AddressAvatar src={avatar} />
          {truncatedAccount}
          <DropdownArrow />
        </WalletConnectedButtonContent>
      </BaseButton>
    </AccountCard>
  ) : (
    <BaseConnectWalletButton onClick={showConnectWalletModal}>
      Connect Wallet
    </BaseConnectWalletButton>
  );
};
