import { ConnectWalletModal } from "@roe-monorepo/shared-features/src/connect-wallet-modal/components/ConnectWalletModal";
import { DisclaimerModal } from "@roe-monorepo/shared-features/src/disclaimer-modal/components/DisclaimerModal";

import { ClosePositionModal } from "../../closePosition/components/ClosePositionModal";
import { OpenPositionModal } from "../../openPosition/components/OpenPositionModal";

import { modalTypes } from "./modalTypes";

export const modalComponents = {
  [modalTypes.connectWallet]: ConnectWalletModal,
  [modalTypes.openPosition]: OpenPositionModal,
  [modalTypes.closePosition]: ClosePositionModal,
  [modalTypes.disclaimer]: DisclaimerModal,
};
