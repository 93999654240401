import styled from "styled-components";
import tw, { theme } from "twin.macro";

import type { TooltipProps } from "../components/Tooltip";

const whiteColor = theme`colors.white`;

type ChildrenContainerProps = Pick<TooltipProps, "disabledUnderline">;

export const Container = tw.div`
  bg-gray
  p-2
  rounded-lg
  border border-white
  max-h-[50vh]
  overflow-auto
`;

export const Arrow = tw.div`
  w-3 h-3
  bg-gray
  rotate-45
  relative
  -top-1.5
  border-r border-b border-white
`;

export const ChildrenContainer = styled.div<ChildrenContainerProps>`
  ${tw`
   flex
   cursor-pointer
  `}

  text-decoration: ${({ disabledUnderline }) =>
    disabledUnderline ? "none" : "underline"};
  text-decoration-style: dotted;
  text-decoration-color: ${whiteColor};
`;
