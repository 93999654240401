import tw from "twin.macro";

export const Modal = tw.div`
  flex flex-col
  max-w-sm
  p-4 sm:p-6
  bg-gray-dark
  rounded-3xl
  border-2
  border-gray-newSemiLight
`;
