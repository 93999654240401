import tw from "twin.macro";

import { BaseButton } from "../../shared/components/BaseButton";
import { BaseIcon } from "../../shared/components/BaseIcon";

export const Button = tw(BaseButton)`
  text-xl
  border-none
  hover:text-white
  bg-gray-newSemiLight
  px-4 py-3 rounded-lg hover:bg-gray
`;

export const Container = tw.div`
  flex justify-between items-center
`;

export const Content = tw.div`
  flex items-center gap-5
`;

export const WalletIcon = tw(BaseIcon)`
  w-7
`;

export const RightArrowIcon = tw(BaseIcon)`
  w-4
`;
