import { getImageSourceByChainId } from "@roe-monorepo/shared-features/src/icons/helpers/getImageSourceByChainId";
import { getChainConfig } from "@roe-monorepo/shared-features/src/web3/helpers/getChainConfig";

import { Content, Icon, Title } from "../styles/ChainTitle";

import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";
import type { FC } from "react";

interface ChainTitleProps {
  chainId: ChainId;
}

export const ChainTitle: FC<ChainTitleProps> = ({ chainId }) => {
  const chainConfig = getChainConfig(chainId);
  const { title, chainId: chainConfigChainId } = chainConfig;
  const iconSource = getImageSourceByChainId(chainConfigChainId);

  return (
    <Content>
      {iconSource ? <Icon src={iconSource} /> : null}
      <Title>{title}</Title>
    </Content>
  );
};
