import styled from "styled-components";
import tw from "twin.macro";

export const Container = styled.div`
  ${tw`w-full bg-gray-dark rounded-b-lg overflow-hidden`}

  table {
    ${tw`w-full`}
  }

  thead > tr {
    ${tw`border-transparent`}
  }

  th {
    :first-child {
      ${tw`pl-8 w-full`}
    }
  }

  tr {
    ${tw`bg-gray-dark border-gray-newSemiLight border-t hover:(bg-gray)`}

    td {
      :first-child {
        ${tw`pl-8`}
      }
    }
  }
`;
