import { Oval } from "react-loader-spinner";

import { BaseMmButton } from "../../shared/components/BaseMmButton";
import { BaseMMButtonColor } from "../../shared/types/baseMmButtonProps";
import { Container } from "../styles/LoadingMainButton";

export const LoadingMainButton = () => (
  <BaseMmButton color={BaseMMButtonColor.GREEN}>
    <Container>
      Opening Trade
      <Oval
        color="#FFFFFF"
        height={24}
        secondaryColor="transparent"
        strokeWidth={3}
        strokeWidthSecondary={3}
        visible
        width={24}
        wrapperClass=""
        wrapperStyle={{}}
      />
    </Container>
  </BaseMmButton>
);
