import { getChainConfig } from "./getChainConfig";

import type { ChainId } from "../types/ChainId";
import type { AddEthereumChainParameter } from "@web3-react/types";

export const getChainParameters = (
  chainId: ChainId
): AddEthereumChainParameter => {
  const { title, nativeCurrency, providers, urls } = getChainConfig(chainId);

  const { name, symbol } = nativeCurrency;
  const { rpc } = providers;
  const { explorer } = urls;

  return {
    chainId,
    chainName: title,

    nativeCurrency: {
      name,
      symbol,
      decimals: 18,
    },

    rpcUrls: [rpc],
    blockExplorerUrls: [explorer],
  };
};
