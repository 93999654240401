import {
  Container,
  Content,
  TitlesContainer,
  Title,
  Link,
  Icon,
} from "../styles/PageBanner";

import type { PageBannerProps } from "../types/PageBannerProps";
import type { FC } from "react";

export const PageBanner: FC<PageBannerProps> = ({
  backgroundSrc: backgroundSource,
  iconSrc: iconSource,
  titles,
  link,
}) => (
  <Container backgroundSrc={backgroundSource}>
    <Content>
      <TitlesContainer>
        {titles.map((title, index) => (
          <Title key={`${title}${index.toString()}`}>{title}</Title>
        ))}
      </TitlesContainer>
      <Link href={link}>Click to read more</Link>
    </Content>
    <Icon src={iconSource} />
  </Container>
);
