import { useBaseTransaction } from "@roe-monorepo/shared-features/src/transactions/hooks/useBaseTransaction";
import { getDefaultProvider } from "ethers";
import { useCallback } from "react";

import { RoePM__factory as RoePMFactory } from "../../smart-contracts/types";

import type {
  DependantQueries,
  OnTransactionSuccess,
} from "@roe-monorepo/shared-features/src/transactions/types/BaseTransaction";

export const useClosePositionTransaction = (
  roePmAddress: string,
  dependantQueries?: DependantQueries,
  onTransactionSuccess?: OnTransactionSuccess
) => {
  const roePMContract = RoePMFactory.connect(
    roePmAddress,
    getDefaultProvider()
  );

  const method = "closeAndWithdrawCollateral";

  const { mutation, resetTransaction, transactionHash } = useBaseTransaction(
    roePMContract,
    method,
    dependantQueries,
    onTransactionSuccess
  );

  const runTransaction = useCallback(
    (poolId: number, tokenAddress: string, lpTokenAddress: string) => {
      mutation.mutate([
        poolId,
        lpTokenAddress,
        0,
        tokenAddress,
        { gasLimit: 1_500_000 },
      ]);
    },
    [mutation]
  );

  return {
    mutation,
    resetTransaction,
    transactionHash,
    runTransaction,
  };
};
