import { useCallback } from "react";

import { closePositionModalType } from "../../modal/constants/closePositionModalType";
import { useModal } from "../../modal/hooks/useModal";

import type { PairRow } from "@roe-monorepo/shared-features/src/shared/types/PairRow";

export const useClosePositionModal = () => {
  const { pushModal, modalState } = useModal();

  const showClosePositionModal = useCallback(
    (pair: PairRow) => {
      pushModal(closePositionModalType, pair);
    },
    [pushModal]
  );

  return { showClosePositionModal, modalState };
};
