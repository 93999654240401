export const links = {
  twitter: "https://twitter.com/RoeFinance",
  discord: "https://dsc.gg/roefinance",
  medium: "https://medium.com/@Roefinance",

  privacyPolicy:
    "https://github.com/RoeFinance/roe-docs/blob/main/Privacy_Policy.pdf",

  termsAndConditions:
    "https://github.com/RoeFinance/roe-docs/blob/main/Terms.pdf",

  gitBook: "https://docs.roe.finance/roe-finance",
  gitBookSupply: "https://docs.roe.finance/roe-finance/products/supply",
  gitBookTrade: "https://docs.roe.finance/roe-finance/products/trade",

  gitBookIntroduction:
    "https://docs.roe.finance/roe-finance/introduction/first-time-here",

  gitHub: "https://github.com/RoeFinance/RoeMarkets",

  roeSupply: "https://supply.roe.finance",
  roeTrade: "https://trade.roe.finance",

  tradeProfitCalculator:
    "https://docs.google.com/spreadsheets/d/1pcYrg6hoePTzw96faph2g1cH8rVMRp87gcSsBS4HykQ",

  crew3Quest: "https://google.com",
};
