import {
  Container,
  CardIconContainer,
  CardIcon,
  CardText,
  Title,
  ValueContainer,
  Value,
  SubTitle,
} from "../styles/TinyInfoCard";

import type { TinyInfoCardProps } from "../types/TinyInfoCardProps";
import type { FC } from "react";

export const TinyInfoCard: FC<TinyInfoCardProps> = ({
  icon,
  title,
  value,
  prefix,
  postfix,
}) => (
  <Container>
    <CardIconContainer>
      <CardIcon src={icon} />
    </CardIconContainer>
    <CardText>
      <Title>{title}</Title>
      <ValueContainer>
        {typeof prefix === "string" ? <SubTitle>{prefix}</SubTitle> : prefix}
        <Value>{value}</Value>
        {typeof postfix === "string" ? <SubTitle>{postfix}</SubTitle> : postfix}
      </ValueContainer>
    </CardText>
  </Container>
);
