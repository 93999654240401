import { getFormattedAmount } from "../../shared/helpers/formatters";

import type { TokenData } from "@roe-monorepo/shared-features/src/queries/types/Token";

export const getFormattedTokenBalance = (tokenData: TokenData) => {
  if (tokenData === undefined) {
    return "-";
  }

  const { balance } = tokenData;

  if (balance === null) {
    return "N/A";
  }

  return getFormattedAmount(balance);
};
