import { useCallback } from "react";

import { localStorageKey } from "../constants/lastConnectedWalletType";
import { WalletType } from "../types/WalletType";

import type { LastConnectedWalletTypeState } from "../types/LastConnectedWalletTypeState";

export const useLastConnectedWalletType = (): LastConnectedWalletTypeState => {
  const rawLastWalletType = localStorage.getItem(
    localStorageKey
  ) as WalletType | null;

  const lastWalletType =
    rawLastWalletType && rawLastWalletType in WalletType
      ? rawLastWalletType
      : undefined;

  const setLastWalletType = useCallback((walletType: WalletType) => {
    localStorage.setItem(localStorageKey, walletType);
  }, []);

  const removeLastWalletType = useCallback(() => {
    localStorage.removeItem(localStorageKey);
  }, []);

  return {
    lastWalletType,
    setLastWalletType,
    removeLastWalletType,
  };
};
