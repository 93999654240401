export { default as btc } from "./btc.svg";
export { default as eth } from "./eth.svg";
export { default as matic } from "./matic.svg";
export { default as usdc } from "./usdc.svg";
export { default as fxs } from "./fxs.svg";
export { default as frax } from "./frax.svg";
export { default as arb } from "./arb.svg";
export { default as gmx } from "./gmx.svg";
export { default as good } from "./good.svg";
export { default as esgood } from "./esgood.svg";
