import { getPairConfig } from "@roe-monorepo/shared-features/src/pair/helpers/getPairConfig";
import { useQuery } from "react-query";

import { getPairQueryOptions } from "../helpers/queriesOptions";

export const usePairQuery = (pairId: string, account?: string) => {
  const { id, chainId } = getPairConfig(pairId);
  const queryOptions = getPairQueryOptions(id, chainId, account);

  return useQuery(queryOptions);
};
