import type { ReactNode } from "react";

export enum BigButtonColor {
  BLUE = "BLUE",
  WHITE = "WHITE",
  BRAND = "BRAND",
  GRAY = "GRAY",
  LIGHTGRAY = "LIGHTGRAY",
  RED = "RED",
  GRADIENT = "GRADIENT",
}

export interface BigButtonProps {
  color: BigButtonColor;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
  children: ReactNode;
}
