import { useEffect } from "react";

import { walletConnectors } from "../constants/walletConnectors";

import { useLastConnectedWalletType } from "./useLastConnectedWalletType";

export const useConnectLastWallet = () => {
  const { lastWalletType } = useLastConnectedWalletType();

  useEffect(() => {
    if (lastWalletType) {
      const [lastWallet] = walletConnectors[lastWalletType];

      void lastWallet.connectEagerly();
    }

    // because we don't need to reconnect each time we change lastWalletType
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
