/* eslint-disable react/forbid-component-props */
import { Button, GradientText } from "../styles/BigButton";
import { BigButtonColor } from "../types/bigButtonProps";

import type { BigButtonProps } from "../types/bigButtonProps";
import type { FC } from "react";

export const BigButton: FC<BigButtonProps> = ({
  color,
  disabled,
  isLoading,
  className,
  onClick,
  children,
}) => (
  <Button
    className={className}
    color={color}
    disabled={Boolean(disabled) || Boolean(isLoading)}
    onClick={onClick}
  >
    {color === BigButtonColor.GRADIENT ? (
      <GradientText>{children}</GradientText>
    ) : (
      children
    )}
  </Button>
);
