import { useRef } from "react";

import { Container } from "../styles/AccountCard";

import { AccountCardContent } from "./AccountCardContent";

import type { FC, ReactNode } from "react";

export interface AccountCardDto {
  isShowDropdown: boolean;
  children: ReactNode;
  hideDropdown: () => void;
  disconnect: () => void;
  changeProvider: () => void;
}

export const AccountCard: FC<AccountCardDto> = ({
  isShowDropdown,
  hideDropdown,
  children,
  disconnect,
  changeProvider,
}) => {
  const parentReference = useRef(null);

  return (
    <Container ref={parentReference}>
      {children}
      <AccountCardContent
        changeProvider={changeProvider}
        disconnect={disconnect}
        isShow={isShowDropdown}
        onHide={hideDropdown}
        parentReference={parentReference}
      />
    </Container>
  );
};
