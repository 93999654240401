import tw from "twin.macro";

import { BaseIcon } from "../../shared/components/BaseIcon";

export const Container = tw.div`
  flex justify-center items-center flex-row
  gap-2
`;

export const CardIconContainer = tw.div`
  flex justify-center items-center
  p-1
  bg-brand
  border border-brand-dark
  rounded-lg
`;

export const CardIcon = tw(BaseIcon)`
  w-6
`;

export const CardText = tw.div`
  flex flex-col gap-1
`;

export const Title = tw.span`
  text-sm
  font-medium
  text-gray-light
  leading-none
`;

export const ValueContainer = tw.div`
  flex items-center gap-1
`;

export const Value = tw.span`
  text-lg text-white
  font-semibold
  leading-none
`;

export const SubTitle = tw.span`
  text-gray-light
  leading-none
`;
