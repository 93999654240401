import tw from "twin.macro";

export const Container = tw.div`
  flex justify-center items-center 
  rounded-lg
  bg-gray-dark
  text-white
  p-0.5
  border-2
  border-gray-semiLight
`;

export const SwitcherOption = tw.a`
  flex justify-center items-center
  p-2
  rounded-lg
  text-white
  font-normal
  font-semibold
  leading-4
  hover:bg-gray
`;

export const SwitcherActiveOption = tw(SwitcherOption)`
  bg-brand
  hover:bg-brand
`;
