import { useRef } from "react";

import { Container } from "../styles/Dropdown";

import { DropdownList } from "./DropdownList";

import type { DropdownProps } from "../types/DropdownProps";
import type { FC, PropsWithChildren } from "react";

export const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
  isShowDropdown,
  hideDropdown,
  options,
  align = "left",
  children,
}) => {
  const parentReference = useRef(null);

  return (
    <Container ref={parentReference}>
      {children}
      <DropdownList
        align={align}
        isShow={isShowDropdown}
        onHide={hideDropdown}
        options={options}
        parentReference={parentReference}
      />
    </Container>
  );
};
