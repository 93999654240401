import tw from "twin.macro";

import { BaseButton } from "../../shared/components/BaseButton";
import { BaseIcon } from "../../shared/components/BaseIcon";

export const Button = tw(BaseButton)`
  w-full
  border-none
  bg-transparent
  rounded-none
  hover:text-white
`;

export const Content = tw.div`
  flex items-center gap-2 justify-between text-sm font-medium
`;

export const Link = tw.a`
  hover:bg-gray
`;

export const Icon = tw(BaseIcon)`
  w-5
`;
