import { SkeletonBox } from "../../shared/styles/SkeletonBox";
import { Row, InfoText } from "../styles/InfoRow";

import type { FC, ReactNode } from "react";

interface Props {
  title: string;
  value: ReactNode | string;
  isLoading: boolean;
}

export const InfoRow: FC<Props> = ({ title, value, isLoading }) => (
  <Row>
    <InfoText>{title}</InfoText>
    {isLoading ? (
      <SkeletonBox height={18} width={48} />
    ) : (
      <InfoText>{value}</InfoText>
    )}
  </Row>
);
