import tw from "twin.macro";

import { BaseButton } from "../../shared/components/BaseButton";

export const BaseConnectWalletButton = tw(BaseButton)`
  bg-blue-light
  border-blue-light
  hover:(text-white bg-blue)
`;

export const AddressAvatar = tw.img`
    w-4
    h-4
    rounded-lg
`;

export const WalletConnectedButtonContent = tw.div`
    flex
    items-center
    gap-2
`;
