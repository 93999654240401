import { PairChainIcon } from "@roe-monorepo/shared-features/src/main-page/components/PairChainIcon";
import { toBig } from "@roe-monorepo/shared-features/src/shared/helpers/bigjs";
import { getFormattedCurrency } from "@roe-monorepo/shared-features/src/shared/helpers/formatters";
import { Table } from "@roe-monorepo/shared-features/src/table/components/Table";
import { Tooltip } from "@roe-monorepo/shared-features/src/tooltip/components/Tooltip";
import { useCallback } from "react";

import { getFormattedAPY } from "../../shared/helpers/formatters";
import { useClosePositionModal } from "../hooks/useClosePositionModal";
import { usePositionsTableRows } from "../hooks/usePositionsTableRows";
import { Container, SypplyApyCell } from "../styles/PositionsTable";

import { OpenPositionLabel } from "./OpenPositionLabel";
import { SupplyAPYTooltip } from "./SupplyApyTooltip";
import { TitleCell } from "./TitleCell";

import type { PairRow } from "@roe-monorepo/shared-features/src/shared/types/PairRow";
import type { Column } from "@roe-monorepo/shared-features/src/table/types/Column";

interface Position extends PairRow {
  id: string;
}

const columns: Column<Position>[] = [
  {
    key: "title",
    title: "Assets",
    render: ({ title, tokens }) => <TitleCell title={title} tokens={tokens} />,
  },
  {
    key: "chainId",
    title: "Network",
    render: ({ chainId }) => <PairChainIcon chainId={chainId} />,
  },
  {
    key: "swapFeesAPY",
    title: "Funding Rate",

    render: ({ source, swapFeesAPY, apy }) => {
      const supplyAPY = toBig(String(swapFeesAPY)).add(apy).toNumber();

      return (
        <Tooltip
          content={
            <SupplyAPYTooltip
              apy={apy}
              source={source}
              swapFeesAPY={Number(swapFeesAPY)}
            />
          }
        >
          <SypplyApyCell>{getFormattedAPY(supplyAPY)}</SypplyApyCell>
        </Tooltip>
      );
    },
  },
  {
    key: "healthFactor",
    title: "Health Factor",
    render: ({ healthFactor }) => (healthFactor ? String(healthFactor) : "N/A"),
  },
  {
    key: "supplyValue",
    title: "Position",

    render: ({ supplyValue }) =>
      supplyValue
        ? getFormattedCurrency(supplyValue, { maximumFractionDigits: 2 })
        : "N/A",
  },
  {
    key: "id",
    title: "PNL",

    render: ({ pnl }) =>
      pnl ? getFormattedCurrency(pnl, { maximumFractionDigits: 2 }) : "0.00",
  },
];

const getRowKey = (row: Position) => row.id;

export const PositionsTable = () => {
  const rows = usePositionsTableRows();

  const { showClosePositionModal } = useClosePositionModal();

  const handleRowClick = useCallback(
    (row: PairRow) => {
      showClosePositionModal(row);
    },
    [showClosePositionModal]
  );

  return (
    <Container>
      <Table
        columns={columns}
        getRowKey={getRowKey}
        noRowsTemplate={<OpenPositionLabel />}
        onRowClick={handleRowClick}
        rows={rows}
      />
    </Container>
  );
};
