import tw from "twin.macro";

import { BaseModal } from "../../shared/components/BaseModal";

export const Container = tw(BaseModal)`
  gap-4
  px-5
  py-4
`;

export const Header = tw.div`
  text-base text-white
  font-semibold
`;

export const Content = tw.div`
  flex flex-col gap-3
`;

export const Footer = tw.div`
  font-medium
  text-xs text-white text-center px-3
`;

export const FooterLink = tw.a`
  text-brand
`;
