import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import { useQuery } from "react-query";

import { getDelegatedTokenQueryOptions } from "../helpers/delegatedTokenQueryOptions";

import type { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

export interface DelegatedTokenQueryParameters {
  chainId: ChainId;
  tokenAddress: string | null;
  spenderAddress?: string;
}

export const useDelegatedTokenQuery = ({
  chainId,
  tokenAddress,
  spenderAddress,
}: DelegatedTokenQueryParameters) => {
  const { account } = useWallet();

  const queryOptions = getDelegatedTokenQueryOptions(
    chainId,
    tokenAddress,
    spenderAddress,
    account
  );

  return useQuery(queryOptions);
};
