import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect";

import { getChainRPCsMap } from "../helpers/getChainUrlsMap";
import { WalletType } from "../types/WalletType";

const RPCs = getChainRPCsMap();

const metamaskConnector = initializeConnector<MetaMask>(
  (actions) => new MetaMask(actions)
);

const walletConnectConnector = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect(actions, {
      rpc: RPCs,
    }),
  Object.keys(RPCs).map(Number)
);

const coinbaseConnector = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet(actions, {
      url: RPCs[1][0],
      appName: "Roe App",
    })
);

export const walletConnectors = {
  [WalletType.METAMASK]: metamaskConnector,
  [WalletType.WALLET_CONNECT]: walletConnectConnector,
  [WalletType.COINBASE]: coinbaseConnector,
};
