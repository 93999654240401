import { toBig } from "@roe-monorepo/shared-features/src/shared/helpers/bigjs";

import { usePositionsTableRows } from "./usePositionsTableRows";

export interface PositionInfoData {
  balance: string;
  healthFactor: string;
  initialDeposit: string;
  pnl: string;
}

export const usePositionInfoData = () => {
  const rows = usePositionsTableRows();

  const data = rows.reduce<PositionInfoData>(
    (previous: PositionInfoData, current) => ({
      balance: toBig(previous.balance)
        .add(current?.supplyValue ?? "0")
        .toFixed(2),

      healthFactor: toBig(previous.healthFactor)
        .add(current?.healthFactor ?? "0")
        .toFixed(4),

      initialDeposit: toBig(previous.initialDeposit)
        .add(current?.initialDeposit ?? "0")
        .toFixed(2),

      pnl: toBig(previous.pnl)
        .add(current?.pnl ?? "0")
        .toFixed(2),
    }),
    {
      balance: "0.00",
      healthFactor: "0.00",
      initialDeposit: "0.00",
      pnl: "0.00",
    }
  );

  data.healthFactor = toBig(data.healthFactor)
    .div(rows.length > 0 ? rows.length : 1)
    .toFixed(4);

  return data;
};
