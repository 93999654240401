import { defaultUseMutationResult } from "@roe-monorepo/shared-features/src/transactions/constants/defaultUseMutationResult";
import { getChainMetadata } from "@roe-monorepo/shared-features/src/web3/helpers/getChainMetadata";
import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import { createContext } from "react";

import { useShowOpenPositionModal } from "../../main-page/hooks/useOpenPositionModal";
import { usePairQueries } from "../../main-page/hooks/usePairQueries";
import { useOpenPositionTransaction } from "../../transactions/hooks/useOpenPositionTransaction";
import { useOpenPositionModalState } from "../hooks/useOpenPositionModalState";

import type { OpenPositionModalContextTransactions } from "../types/OpenPositionModalContextTransactions";
import type { FC, ReactNode } from "react";

interface OpenPositionModalTransactionsProviderProps {
  children: ReactNode;
}

export const OpenPositionModalTransactionsContext =
  createContext<OpenPositionModalContextTransactions>({
    mutation: defaultUseMutationResult.mutation,
    resetTransaction: () => undefined,
    transactionHash: undefined,

    runTransaction: async () => {
      await Promise.resolve(undefined);
    },
  });

export const OpenPositionModalTransactionsProvider: FC<
  OpenPositionModalTransactionsProviderProps
> = ({ children }) => {
  const { setInputValue } = useOpenPositionModalState();

  const { chainId = 1 } = useWallet();
  const { addresses } = getChainMetadata(chainId);

  const { modalState } = useShowOpenPositionModal();

  const { roePm } = addresses;

  const qsOracle = modalState?.addresses?.qsOracle ?? "";

  const { pairQuery, pairTokenQuery, aTokenQuery } = usePairQueries();

  const onTransactionSuccess = () => {
    setInputValue("");
  };

  const openPositionTransaction = useOpenPositionTransaction(
    roePm,
    String(qsOracle),
    [pairQuery, pairTokenQuery, aTokenQuery],
    onTransactionSuccess
  );

  return (
    <OpenPositionModalTransactionsContext.Provider
      value={openPositionTransaction}
    >
      {children}
    </OpenPositionModalTransactionsContext.Provider>
  );
};
