import styled, { css } from "styled-components";
import tw, { theme } from "twin.macro";

import { BigButtonColor } from "../types/bigButtonProps";

import type { BigButtonProps } from "../types/bigButtonProps";

const grayDarkColor = theme`colors.gray.dark`;
const gradientColor =
  "linear-gradient(90deg, #ff4e02 0%, #fd800e 25%, #fba617 82%, #fc601e 135%)";

const blueButtonStyle = tw`
  text-white
  bg-blue-light
  hover:(bg-blue)
`;

const whiteButtonStyle = tw`
  bg-white
  hover:(bg-white-dark)
`;

const brandButtonStyle = tw`
  text-white
  bg-brand
  hover:(bg-brand-dark)
`;

const grayButtonStyle = tw`
  text-white
  bg-gray
`;

const lightGrayButtonStyle = tw`
  text-white
  bg-gray-newLight
`;

const redButtonStyle = tw`
  text-white
  bg-red-dark
`;

const gradientButtonStyle = css`
  background: linear-gradient(${grayDarkColor}, ${grayDarkColor}) padding-box,
    ${gradientColor} border-box;
  border: 1px solid transparent;
`;

const bigButtonStyles = {
  [BigButtonColor.BLUE]: blueButtonStyle,
  [BigButtonColor.WHITE]: whiteButtonStyle,
  [BigButtonColor.BRAND]: brandButtonStyle,
  [BigButtonColor.GRAY]: grayButtonStyle,
  [BigButtonColor.LIGHTGRAY]: lightGrayButtonStyle,
  [BigButtonColor.RED]: redButtonStyle,
  [BigButtonColor.GRADIENT]: gradientButtonStyle,
};

export const Button = styled.button<BigButtonProps>`
  ${tw`
    px-3 py-3
    rounded-lg
    text-lg
    font-semibold
    transition 
  `}

  ${({ disabled = false }) =>
    disabled ? tw`opacity-60 cursor-not-allowed` : tw`active:scale-90`}

  ${({ color }) => bigButtonStyles[color]}
`;

export const GradientText = styled.span`
  background: ${gradientColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
