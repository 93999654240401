import { defaultUseMutationResult } from "@roe-monorepo/shared-features/src/transactions/constants/defaultUseMutationResult";
import { getChainMetadata } from "@roe-monorepo/shared-features/src/web3/helpers/getChainMetadata";
import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import { createContext } from "react";

import { usePairQueries } from "../../main-page/hooks/usePairQueries";
import { useClosePositionTransaction } from "../../transactions/hooks/useClosePositionTransaction";

import type { ClosePositionModalContextTransactions } from "../types/ClosePositionModalContextTransactions";
import type { FC, ReactNode } from "react";

interface OpenPositionModalTransactionsProviderProps {
  children: ReactNode;
}

export const ClosePositionModalTransactionsContext =
  createContext<ClosePositionModalContextTransactions>(
    defaultUseMutationResult
  );

export const ClosePositionModalTransactionsProvider: FC<
  OpenPositionModalTransactionsProviderProps
> = ({ children }) => {
  const { chainId = 1 } = useWallet();
  const { addresses } = getChainMetadata(chainId);
  const { roePm } = addresses;

  const { pairQuery, pairTokenQuery, aTokenQuery } = usePairQueries();

  const closePositionTransaction = useClosePositionTransaction(roePm, [
    pairQuery,
    pairTokenQuery,
    aTokenQuery,
  ]);

  return (
    <ClosePositionModalTransactionsContext.Provider
      value={closePositionTransaction}
    >
      {children}
    </ClosePositionModalTransactionsContext.Provider>
  );
};
