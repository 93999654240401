import { useCallback } from "react";

import { modalTypes } from "../../modal/constants/modalTypes";
import { useModal } from "../../modal/hooks/useModal";
import { BaseMmButton } from "../../shared/components/BaseMmButton";
import { BaseMMButtonColor } from "../../shared/types/baseMmButtonProps";

export const ConnectWalletMainButton = () => {
  const { pushModal } = useModal();

  const showConnectWalletModal = useCallback(() => {
    pushModal(modalTypes.connectWallet);
  }, [pushModal]);

  return (
    <BaseMmButton
      color={BaseMMButtonColor.BLUE}
      onClick={showConnectWalletModal}
    >
      Connect Wallet
    </BaseMmButton>
  );
};
