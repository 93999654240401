import tw from "twin.macro";

export const Row = tw.div`
  flex justify-between flex-row items-center
  w-full
  px-2
  first:pt-2
  last:pb-2
`;

export const InfoText = tw.span`
  text-white
  text-base
  font-semibold
`;
