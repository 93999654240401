import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { useKey, useOutsideClickRef } from "rooks";

import { disclaimerModalType } from "../../disclaimer-modal/constants/disclaimerModalType";
import { isDisclaimerShown } from "../../disclaimer-modal/constants/isDisclaimerShown";
import { getModalComponent } from "../helpers/getModalComponent";
import { useModal } from "../hooks/useModal";
import { Backdrop, ModalContainer } from "../styles/Modal";

export const Modal = () => {
  const { modalType, hideModal, modalComponents, pushModal } = useModal();
  const ModalComponent = getModalComponent(modalType, modalComponents);

  const hideModalOverrided = () => {
    if (isDisclaimerShown()) {
      hideModal();
    }
  };

  useKey("Escape", hideModalOverrided);

  const [modalContainerReference] = useOutsideClickRef(hideModalOverrided);

  useEffect(() => {
    if (!isDisclaimerShown()) {
      pushModal(disclaimerModalType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence>
      {modalType ? (
        <Backdrop>
          <ModalContainer ref={modalContainerReference}>
            <ModalComponent />
          </ModalContainer>
        </Backdrop>
      ) : null}
    </AnimatePresence>
  );
};
