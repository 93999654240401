import { getZero } from "@roe-monorepo/shared-features/src/shared/helpers/bigjs";
import { getChainMetadata } from "@roe-monorepo/shared-features/src/web3/helpers/getChainMetadata";
import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import { useCallback } from "react";

import { BaseMmButton } from "../../shared/components/BaseMmButton";
import { BaseMMButtonColor } from "../../shared/types/baseMmButtonProps";
import { useDelegatedTokenApproveTransaction } from "../hooks/useDelegatedTokenApproveTransaction";
import { useOpenPositionModalQueries } from "../hooks/useOpenPositionModalQueries";
import { useOpenPositionModalState } from "../hooks/useOpenPositionModalState";

export const ApproveDelegatedMainButton = () => {
  const { inputValueBig } = useOpenPositionModalState();

  const { delegatedAllowanceTokenQuery, pairData } =
    useOpenPositionModalQueries();

  const { variableDebtTokenAddress = "" } = pairData ?? {};

  const { mutation, resetTransaction, runTransaction } =
    useDelegatedTokenApproveTransaction(variableDebtTokenAddress, [
      delegatedAllowanceTokenQuery,
    ]);

  const { chainId = 1 } = useWallet();
  const { addresses } = getChainMetadata(chainId);
  const { roePm } = addresses;

  const handleButtonClick = useCallback(() => {
    runTransaction(roePm);
  }, [runTransaction, roePm]);

  const { isError, isLoading, error } = mutation;
  const { message = "Please, try again later" } = error ?? {};

  const isButtonDisabled = inputValueBig.lte(getZero());

  if (isError) {
    return (
      <BaseMmButton color={BaseMMButtonColor.RED} onClick={resetTransaction}>
        {message}
      </BaseMmButton>
    );
  }

  return (
    <BaseMmButton
      color={BaseMMButtonColor.WHITE}
      disabled={isButtonDisabled}
      isLoading={isLoading}
      onClick={handleButtonClick}
    >
      {isLoading ? "Approving..." : "Approve Delegated Allowance"}
    </BaseMmButton>
  );
};
