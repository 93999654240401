export const getFormattedCurrency = (
  value: number,
  options?: Partial<Intl.NumberFormatOptions>
) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...options,
  });

  return currencyFormatter.format(value);
};

export const getFormattedAPY = (
  value: number,
  options?: Partial<Intl.NumberFormatOptions>
) => {
  const apyFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    ...options,
  });

  return apyFormatter.format(value);
};
