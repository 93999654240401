import { useRef, useState } from "react";

import { useModal } from "../../modal/hooks/useModal";
import { setIsDisclaimerShown } from "../constants/isDisclaimerShown";
import {
  AgreeButton,
  Container,
  Content,
  Footer,
  Header,
  Paragraph,
} from "../styles/DisclaimerModal";

export const DisclaimerModal = () => {
  const { hideModal } = useModal();
  const contentReference = useRef(null);
  const [isDisclaimerReaded, setIsDisclaimerReaded] = useState(false);

  const onScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      contentReference?.current ?? {
        scrollTop: 0,
        clientHeight: 0,
        scrollHeight: 0,
      };
    const isFullyScrolled = scrollTop + clientHeight >= scrollHeight;
    if (!isDisclaimerReaded) {
      setIsDisclaimerReaded(isFullyScrolled);
    }
  };

  return (
    <Container>
      <Header>Terms of Service</Header>
      <Content onScroll={onScroll} ref={contentReference}>
        <Paragraph>
          By clicking “I agree” below, you agree to be bound by the terms of
          this Agreement. As such, you fully understand that:
        </Paragraph>
        <ul>
          <li>
            ROE Finance is a blockchain-based decentralized finance project. You
            are participating at your own risk.
          </li>
          <li>
            ROE Finance is offered for use “as is” and without any guarantees
            regarding security. The protocol is made up of immutable code and
            can be accessed through a variety of user interfaces.
          </li>
          <li>
            No central entity operates the ROE Finance protocol. Decisions
            related to the protocol are governed by a dispersed group of
            participants who collectively govern and maintain the protocol.
          </li>
          <li>
            ROE Finance does not unilaterally offer, maintain, operate,
            administer, or control any trading interfaces. The only user
            interfaces maintained by ROE Finance are the governance and staking
            interfaces herein.
          </li>
          <li>
            You can participate in the governance process by staking SNX tokens
            in accordance with the rules and parameters summarized here, and/or
            joining the Synthetix Discord and contributing to the conversation.
          </li>
          <li>
            The rules and parameters associated with the Synthetix protocol and
            Synthetix DAO governance are subject to change at any time.
          </li>
          <li>
            Your use of Synthetix is conditioned upon your acceptance to be
            bound by the Synthetix Term of Use, which can be found here.
          </li>
          <li>
            The laws that apply to your use of Synthetix may vary based upon the
            jurisdiction in which you are located. We strongly encourage you to
            speak with legal counsel in your jurisdiction if you have any
            questions regarding your use of Synthetix.
          </li>{" "}
          By entering into this agreement, you are not agreeing to enter into a
          partnership. You understand that Synthetix is a decentralized protocol
          provided on an “as is” basis.
          <li>
            You hereby release all present and future claims against Synthetix
            DAO related to your use of the protocol, the SNX token, SNX DAO
            governance, and any other facet of the protocol.
          </li>
          <li>
            You agree to indemnify and hold harmless SNX DAO and its affiliates
            for any costs arising out of or relating to your use of the
            Synthetix protocol.
          </li>
          <li>
            You are not accessing the protocol from Burma (Myanmar), Cuba, Iran,
            Sudan, Syria, the Western Balkans, Belarus, Côte d’Ivoire,
            Democratic Republic of the Congo, Iraq, Lebanon, Liberia, Libya,
            North Korea, Russia, certain sanctioned areas of Ukraine, Somalia,
            Venezuela, Yemen, or Zimbabwe (collectively, “Prohibited
            Jurisdictions”), or any other jurisdiction listed as a Specially
            Designated National by the United States Office of Foreign Asset
            Control (“OFAC”).
          </li>
        </ul>
      </Content>
      <Footer>
        <AgreeButton
          disabled={!isDisclaimerReaded}
          onClick={() => {
            setIsDisclaimerShown();
            hideModal();
          }}
        >
          I agree
        </AgreeButton>
      </Footer>
    </Container>
  );
};
