import { DropdownArrow } from "../../dropdown/components/DropdownArrow";
import { Content, Icon } from "../../dropdown/styles/DropdownOption";
import { getImageSourceByChainId } from "../../icons/helpers/getImageSourceByChainId";
import { BaseButton } from "../../shared/components/BaseButton";
import { getChainConfig } from "../helpers/getChainConfig";
import { useWallet } from "../hooks/useWallet";
import { ChainTitle } from "../styles/ChainButton";

import type { ChainId } from "../types/ChainId";
import type { FC } from "react";

interface ChainButtonProps {
  supportedChainIds: ChainId[];
  onClick: () => void;
}

export const ChainButton: FC<ChainButtonProps> = ({
  supportedChainIds,
  onClick,
}) => {
  const { isConnected, chainId } = useWallet();

  if (!isConnected || !chainId) {
    return <BaseButton disabled>Network</BaseButton>;
  }

  if (chainId && !supportedChainIds.includes(chainId)) {
    return (
      <BaseButton isError onClick={onClick}>
        Wrong Network
      </BaseButton>
    );
  }

  const chainConfig = getChainConfig(chainId);
  const { title, chainId: chainConfigChainId } = chainConfig;
  const iconSource = getImageSourceByChainId(chainConfigChainId);

  return (
    <BaseButton onClick={onClick}>
      <Content>
        {iconSource ? <Icon src={iconSource} /> : null}
        <ChainTitle>{title}</ChainTitle>
        <DropdownArrow />
      </Content>
    </BaseButton>
  );
};
