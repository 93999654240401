import { metamask, walletConnect, coinbase } from "../../icons/wallet";
import { WalletType } from "../../web3/types/WalletType";

export const walletButtonConfigs = {
  [WalletType.METAMASK]: {
    title: "MetaMask",
    iconSource: metamask,
  },

  [WalletType.WALLET_CONNECT]: {
    title: "WalletConnect",
    iconSource: walletConnect,
  },

  [WalletType.COINBASE]: {
    title: "Coinbase Wallet",
    iconSource: coinbase,
  },
};
