import { useCallback, useEffect, useState } from "react";
import { Blocks } from "react-loader-spinner";

import { renderCellContent } from "../helpers/renderCellContent";
import { useFilteredBy } from "../hooks/useFilterBy";
import { useSortBy } from "../hooks/useSortBy";
import { HeaderRow, Row, Cell, HeaderCell } from "../styles/Table";

import { Header } from "./Header";
import { NotFound } from "./NotFound";

import type { TableProps } from "../types/TableProps";

export const Table = <RowData extends object>({
  columns,
  rows,
  getRowKey,
  filterInputValue,
  onRowClick = () => undefined,
  noRowsTemplate = <NotFound />,
}: TableProps<RowData>) => {
  const { filteredRows } = useFilteredBy(columns, rows, filterInputValue);
  const { sortedRows } = useSortBy(filteredRows);

  const handleRowClick = useCallback(
    (row: RowData | undefined) => {
      if (row) {
        onRowClick(row);
      }
    },
    [onRowClick]
  );

  const [isRowsLoading, setIsRowsLoading] = useState(true);

  useEffect(() => {
    setIsRowsLoading(
      rows.length > 0 &&
        !rows.reduce((previous, current) => previous && Boolean(current), true)
    );
  }, [rows]);

  return (
    <table>
      <thead>
        <HeaderRow>
          {columns.map(({ title, key }, columnIndex) => (
            <HeaderCell
              align={columnIndex === 0 ? "left" : "right"}
              key={`${String(key)}${columnIndex.toString()}`}
            >
              {title ? <Header title={title} /> : null}
            </HeaderCell>
          ))}
        </HeaderRow>
      </thead>
      <tbody>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isRowsLoading ? (
          <tr>
            <td className="empty" colSpan={columns.length}>
              <Blocks
                ariaLabel="blocks-loading"
                height="50"
                visible
                width="calc(100% - 36px)"
                wrapperClass="blocks-wrapper"
              />
            </td>
          </tr>
        ) : sortedRows.length === 0 ? (
          <tr>
            <td className="empty" colSpan={columns.length}>
              {noRowsTemplate}
            </td>
          </tr>
        ) : (
          sortedRows.map((row, rowIndex) => (
            <Row
              key={row && getRowKey ? getRowKey(row) : rowIndex}
              onClick={() => {
                handleRowClick(row);
              }}
            >
              {columns.map((column, columnIndex) => (
                <Cell
                  align={columnIndex === 0 ? "left" : "right"}
                  key={column.key?.toString() ?? columnIndex.toString()}
                >
                  {renderCellContent(row, column)}
                </Cell>
              ))}
            </Row>
          ))
        )}
      </tbody>
    </table>
  );
};
