import Tippy from "@tippyjs/react/headless";

import { Container, Arrow, ChildrenContainer } from "../styles/Tooltip";

import type { TippyProps } from "@tippyjs/react/headless";
import type { FC } from "react";

export interface TooltipProps extends Pick<TippyProps, "children" | "content"> {
  disabledUnderline?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({
  content,
  children,
  disabledUnderline,
}) => (
  <Tippy
    appendTo={() => document.body}
    interactive
    render={(attributes) => (
      <div {...attributes}>
        <Container>{content}</Container>
        <div data-popper-arrow="">
          <Arrow />
        </div>
      </div>
    )}
  >
    <ChildrenContainer disabledUnderline={disabledUnderline}>
      {children}
    </ChildrenContainer>
  </Tippy>
);
