import { ChainId } from "@roe-monorepo/shared-features/src/web3/types/ChainId";

import { TitleCell } from "../../main-page/components/TitleCell";
import { useModal } from "../../modal/hooks/useModal";
import { ChainTitle } from "../../shared/components/ChainTitle";
import { ModalTextExplanation } from "../../shared/components/ModalTextExplanation";
import { OpenPositionModalStateProvider } from "../providers/OpenPositionModalStateProvider";
import { OpenPositionModalTransactionsProvider } from "../providers/OpenPositionModalTransactionsProvider";
import { Container, Title, Wrapper } from "../styles/OpenPositionModal";

import { MainButton } from "./MainButton";
import { OpenPositionInputCard } from "./OpenPositionInputCard";
import { OpenPositionTransactionLink } from "./OpenPositionTransactionLink";
import { OpenTradeInfo } from "./OpenTradeInfo";

export const OpenPositionModal = () => {
  const { modalState } = useModal();

  const {
    title = "-",
    tokens = ["-", "-"],
    chainId = ChainId.MAINNET,
  } = modalState ?? {};

  return (
    <OpenPositionModalTransactionsProvider>
      <OpenPositionModalStateProvider>
        <Wrapper>
          <Container>
            <Title>
              <TitleCell title={title} tokens={tokens} />
              <ChainTitle chainId={chainId} />
            </Title>
            <ModalTextExplanation />
            <OpenPositionInputCard />
            <MainButton />
            <OpenPositionTransactionLink />
            <OpenTradeInfo />
          </Container>
        </Wrapper>
      </OpenPositionModalStateProvider>
    </OpenPositionModalTransactionsProvider>
  );
};
