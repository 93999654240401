import { links } from "../../shared/constants/links";
import { WalletType } from "../../web3/types/WalletType";
import {
  Container,
  Content,
  Footer,
  FooterLink,
  Header,
} from "../styles/ConnectWalletModal";

import { WalletButton } from "./WalletButton";

export const ConnectWalletModal = () => (
  <Container>
    <Header>Connect wallet</Header>
    <Content>
      <WalletButton walletType={WalletType.METAMASK} />
      <WalletButton walletType={WalletType.WALLET_CONNECT} />
      <WalletButton walletType={WalletType.COINBASE} />
    </Content>
    <Footer>
      By connecting your wallet, you agree to our{" "}
      <FooterLink href={links.termsAndConditions} target="_blank">
        Terms and Conditions
      </FooterLink>{" "}
      and{" "}
      <FooterLink href={links.privacyPolicy} target="_blank">
        Privacy Policy
      </FooterLink>
    </Footer>
  </Container>
);
