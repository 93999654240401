import styled from "styled-components";
import tw from "twin.macro";

import { BaseIcon } from "../../shared/components/BaseIcon";
import { BigButton } from "../../shared/components/BigButton";
import { BigButtonColor } from "../../shared/types/bigButtonProps";

interface InputProps {
  isError: boolean;
}

export const Container = styled.div`
  ${tw`
      flex flex-col gap-1
  px-3 py-3
  bg-gray-newSemiLight
  border
  border-gray-newLight
  rounded-lg
    `}
`;

export const InputContainer = tw.div`
  flex items-center gap-4
`;

export const Input = styled.input.attrs<InputProps>({
  type: "number",
  inputMode: "decimal",
  placeholder: "0",
  min: "0",
  step: "any",
})<InputProps>`
  ${tw`
    flex flex-1
    min-w-0
    text-lg text-white
    font-semibold
    p-0
    bg-transparent
    border-none
    outline-none
    transition
    overflow-ellipsis
  `}

  ${({ isError }) => isError && tw`text-red-dark`}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const TokenSymbol = tw.span`
  text-white
  bg-gray
  px-3 py-1
  flex text-sm font-medium
  rounded-lg
  border-none
`;

export const BalanceContainer = tw.div`
  flex justify-end items-center gap-1
`;

export const WalletIcon = tw(BaseIcon)`
  w-4
`;

export const BalanceValue = tw.span`
  text-sm text-gray-light
  font-semibold
`;

export const MaxButton = styled(BigButton).attrs({
  color: BigButtonColor.LIGHTGRAY,
})`
  ${tw`
    px-1 py-0
    text-xs text-black-light
    font-normal
    rounded-3xl
    uppercase
  `}
`;
