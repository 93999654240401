import { Tooltip } from "@roe-monorepo/shared-features/src/tooltip/components/Tooltip";

import type { FC, ReactElement } from "react";

interface DevelopmentExplanationWrapperProps {
  explanation: ReactElement;
  children: ReactElement;
}

export const DevelopmentExplanation: FC<DevelopmentExplanationWrapperProps> = ({
  explanation,
  children,
}) => {
  const developmentMode = localStorage.getItem("devMode");

  return developmentMode ? (
    <Tooltip content={explanation}>{children}</Tooltip>
  ) : (
    children
  );
};
