/* eslint-disable react/forbid-component-props */
import { Button } from "../styles/BaseMmButton";

import type { BaseMmButtonProps } from "../types/baseMmButtonProps";
import type { FC } from "react";

export const BaseMmButton: FC<BaseMmButtonProps> = ({
  color,
  disabled,
  isLoading,
  className,
  onClick,
  children,
}) => (
  <Button
    className={className}
    color={color}
    disabled={Boolean(disabled) || Boolean(isLoading)}
    onClick={onClick}
  >
    {children}
  </Button>
);
