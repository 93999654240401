import { useBaseTransaction } from "@roe-monorepo/shared-features/src/transactions/hooks/useBaseTransaction";
import { constants, getDefaultProvider } from "ethers";
import { useCallback } from "react";

import { IERC20__factory as ERC20Factory } from "../../../smart-contracts/types";

import type {
  DependantQueries,
  OnTransactionSuccess,
} from "@roe-monorepo/shared-features/src/transactions/types/BaseTransaction";

export const useTokenApproveTransaction = (
  tokenAddress: string,
  dependantQueries?: DependantQueries,
  onTransactionSuccess?: OnTransactionSuccess
) => {
  const tokenContract = ERC20Factory.connect(
    tokenAddress,
    getDefaultProvider()
  );
  const method = "approve";

  const { mutation, resetTransaction, transactionHash } = useBaseTransaction(
    tokenContract,
    method,
    dependantQueries,
    onTransactionSuccess
  );

  const runTransaction = useCallback(
    (lendingPoolAddress: string) => {
      mutation.mutate([lendingPoolAddress, constants.MaxUint256]);
    },
    [mutation]
  );

  return {
    mutation,
    resetTransaction,
    transactionHash,
    runTransaction,
  };
};
