import { getChainMetadata } from "@roe-monorepo/shared-features/src/web3/helpers/getChainMetadata";

import { useModal } from "../../modal/hooks/useModal";
import { usePairQuery } from "../../shared/queries/hooks/usePairQuery";
import { useTokenQuery } from "../../shared/queries/hooks/useTokenQuery";

export const useClosePositionModalQueries = () => {
  const { modalState } = useModal();
  const { id = "" } = modalState ?? {};

  const pairQuery = usePairQuery(id);

  const pairData = pairQuery.data;
  const { collateral, chainId = 1 } = pairData ?? {};
  const { address = "" } = collateral ?? {};
  const { addresses } = getChainMetadata(chainId);
  const { roePm } = addresses;

  const closePositionTokenQuery = useTokenQuery({
    chainId,
    tokenAddress: address,
    spenderAddress: roePm,
  });

  return {
    closePositionTokenQuery,
    pairData,
  };
};
