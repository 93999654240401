import { toATokenQueryParameters } from "@roe-monorepo/shared-features/src/shared/helpers/toATokenQueryParameters";
import { toPairTokenQueryParameters } from "@roe-monorepo/shared-features/src/shared/helpers/toPairTokenQueryParameters";
import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";

import { useModal } from "../../modal/hooks/useModal";
import { usePairQuery } from "../../shared/queries/hooks/usePairQuery";
import { useTokenQuery } from "../../shared/queries/hooks/useTokenQuery";

export const usePairQueries = () => {
  const { modalState } = useModal();
  const { account } = useWallet();
  const { id = "" } = modalState ?? {};

  const pairQuery = usePairQuery(id, account);

  const pairTokenParameters = toPairTokenQueryParameters(id, pairQuery);
  const pairTokenQuery = useTokenQuery(pairTokenParameters);

  const aTokenParameters = toATokenQueryParameters(id, pairQuery);
  const aTokenQuery = useTokenQuery(aTokenParameters);

  return {
    pairQuery,
    pairTokenQuery,
    aTokenQuery,
  };
};
