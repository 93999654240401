import styled, { css } from "styled-components";
import tw from "twin.macro";

import { BaseMMButtonColor } from "../types/baseMmButtonProps";

import type { BaseMmButtonProps } from "../types/baseMmButtonProps";

const blueButtonStyle = tw`
  text-white
  bg-blue-light
  hover:(bg-blue)
`;

const whiteButtonStyle = tw`
  bg-white
  hover:(bg-white-dark)
`;

const brandButtonStyle = tw`
  bg-brand
  hover:(bg-brand-dark)
`;

const grayButtonStyle = tw`
  text-white
  bg-gray
`;

const greenButtonStyle = tw`
  bg-brand
  hover:(bg-brand-dark)
  text-white
`;

const redButtonStyle = tw`
  text-white
  bg-red-dark
`;

const gradientButtonStyle = css`
  background: linear-gradient(291.41deg, #00e660 -15.95%, #3461ff 123.29%);
  ${tw`text-white`}
`;

const bigButtonStyles = {
  [BaseMMButtonColor.BLUE]: blueButtonStyle,
  [BaseMMButtonColor.WHITE]: whiteButtonStyle,
  [BaseMMButtonColor.BRAND]: brandButtonStyle,
  [BaseMMButtonColor.GRAY]: grayButtonStyle,
  [BaseMMButtonColor.GREEN]: greenButtonStyle,
  [BaseMMButtonColor.RED]: redButtonStyle,
  [BaseMMButtonColor.GRADIENT]: gradientButtonStyle,
};

export const Button = styled.button<BaseMmButtonProps>`
  ${tw`
    px-3 py-3
    rounded-lg
    w-full
    text-lg
    leading-6
    font-semibold
    transition
  `}

  ${({ disabled = false }) =>
    disabled ? tw`opacity-60 cursor-not-allowed` : tw`active:scale-90`}

  ${({ color }) => bigButtonStyles[color]}
`;
