import { BaseMmButton } from "../../shared/components/BaseMmButton";
import { BaseMMButtonColor } from "../../shared/types/baseMmButtonProps";
import { useOpenPositionModalTransactions } from "../hooks/useOpenPositionModalTransactions";

export const ErrorMainButton = () => {
  const { mutation, resetTransaction } = useOpenPositionModalTransactions();
  let message = "Something went wrong, please contact us on Discord";

  if (mutation.error && !mutation.error.message.includes("exception")) {
    // eslint-disable-next-line prefer-destructuring
    message = mutation.error.message;

    if (mutation.error.message.includes("cannot estimate gas")) {
      message = "Cannot estimate gas. Please try again later";
    }
  }

  return (
    <BaseMmButton color={BaseMMButtonColor.RED} onClick={resetTransaction}>
      {message}
    </BaseMmButton>
  );
};
