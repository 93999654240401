import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

export const Backdrop = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})`
  ${tw`
    fixed
    flex justify-center items-center
    w-screen h-screen
    z-10
    bg-black/60
  `}
`;

export const ModalContainer = styled(motion.div).attrs({
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
})`
  ${tw`
    flex
    max-w-[95vw] max-h-[95vh]
  `}
`;
