import { connectWalletModalType } from "@roe-monorepo/shared-features/src/connect-wallet-modal/constants/connectWalletModalType";
import { disclaimerModalType } from "@roe-monorepo/shared-features/src/disclaimer-modal/constants/disclaimerModalType";

import { closePositionModalType } from "./closePositionModalType";
import { openPositionModalType } from "./openPositionModalType";

export const modalTypes = {
  connectWallet: connectWalletModalType,
  openPosition: openPositionModalType,
  closePosition: closePositionModalType,
  disclaimer: disclaimerModalType,
} as const;
