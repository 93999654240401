import { useBaseTransaction } from "@roe-monorepo/shared-features/src/transactions/hooks/useBaseTransaction";
import { useWallet } from "@roe-monorepo/shared-features/src/web3/hooks/useWallet";
import Big from "big.js";
import { getDefaultProvider } from "ethers";
import { formatUnits, parseEther } from "ethers/lib/utils";
import { useCallback } from "react";

import {
  RoePM__factory as RoePMFactory,
  CLAgg__factory as CLAggFactory,
} from "../../smart-contracts/types";

import type {
  DependantQueries,
  OnTransactionSuccess,
} from "@roe-monorepo/shared-features/src/transactions/types/BaseTransaction";

export const useOpenPositionTransaction = (
  roePmAddress: string,
  clAddress: string,
  dependantQueries?: DependantQueries,
  onTransactionSuccess?: OnTransactionSuccess
) => {
  const { provider } = useWallet();

  const roePMContract = RoePMFactory.connect(
    roePmAddress,
    getDefaultProvider()
  );

  const tokenCL = CLAggFactory.connect(
    clAddress,
    provider ?? getDefaultProvider()
  );

  const method = "depositCollateralAndOpen";

  const { mutation, resetTransaction, transactionHash } = useBaseTransaction(
    roePMContract,
    method,
    dependantQueries,
    onTransactionSuccess
  );

  const runTransaction = useCallback(
    async (
      poolId: number,
      tokenAddress: string,
      amount: string,
      lpTokenAddress: string,
      lpAmount: string
    ) => {
      const lpRate = await tokenCL.latestAnswer();

      const lPToBorrow = new Big(Number.parseFloat(lpAmount) * 10).div(
        formatUnits(lpRate, 8)
      );

      mutation.mutate([
        poolId,
        tokenAddress,
        amount,
        lpTokenAddress,
        parseEther(lPToBorrow.toFixed(18)),
        { gasLimit: 1_500_000 },
      ]);
    },
    [mutation, tokenCL]
  );

  return {
    mutation,
    resetTransaction,
    transactionHash,
    runTransaction,
  };
};
